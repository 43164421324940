import React, { useEffect, useState } from "react";
import { withSnackbar } from "notistack";
import {
  Visibility as IconEyeOn,
  VisibilityOff as IconEyeOff,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";

import Loader from "@components/Custom/Loader";
import TxtBox from "@components/Custom/TxtBox";
import Btn from "@components/Custom/Btn";
import ChkBox from "@components/Custom/ChkBox";

import { ApiLogin } from "@apihandlers/login";
import {
  CONFIG,
  ERRORS,
  LABELS,
  LOCAL_STORAGE,
  NOTIFY,
  SUCCESS,
  WARNINGS,
} from "@common/config";
import { COMMON_JS } from "@common/scripts";

import "./styles/login.css";

const webLogo = require("@assets/images/logo.png");

const API_HANDLER_LOGIN = new ApiLogin();

const LoginPage = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (loading) {
      checkLogin();
    }
  }, [loading]);

  const checkLogin = () => {
    const args = {};
    API_HANDLER_LOGIN.checkLogin(args).then((res) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        if (response.status) {
          window.open("/", "_self");
        }
      }
      setLoading(false);
    });
  };

  const submitLogin = () => {
    // Data
    const userName = (
      document.getElementById("uname") as HTMLInputElement
    ).value.trim();
    const password = (
      document.getElementById("pwd") as HTMLInputElement
    ).value.trim();
    const rememberMe = (
      document.querySelector("input#rememberMe") as HTMLInputElement
    ).checked;

    // Validations
    if (!userName) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.USERNAME_IS_EMPTY);
      return;
    }
    if (!password) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.PASSWORD_IS_EMPTY);
      return;
    }

    // API Call
    const args = {
      userName,
      password,
    };
    if (rememberMe) {
      localStorage.setItem(LOCAL_STORAGE.REMEMBER_ME, JSON.stringify(args));
    } else {
      localStorage.removeItem(LOCAL_STORAGE.REMEMBER_ME);
    }
    API_HANDLER_LOGIN.userLogin(args).then((res: any) => {
      if (res.status === 200) {
        localStorage.setItem(
          LOCAL_STORAGE.USER_INFO,
          JSON.stringify(res.response)
        );
        localStorage.setItem(LOCAL_STORAGE.ACCESS_TOKEN, res.response.token);
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.LOGIN_SUCCESS);
        window.open("/", "_self");
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.LOGIN_FAILED);
      }
    });
  };

  let userInfo: any = localStorage.getItem(LOCAL_STORAGE.REMEMBER_ME);
  if (userInfo) {
    userInfo = JSON.parse(userInfo);
  } else {
    userInfo = {};
  }

  // console.log("LoginPage: ", loading, userInfo);

  return (
    <div className="loginPage">
      {loading ? (
        <div className="flexCenter">
          <Loader />
        </div>
      ) : (
        <div className="loginBox">
          <img src={webLogo} className="responsive" />
          {/* <p style={{ margin: 0, textAlign: "center", fontSize: 10 }}>
            {CONFIG.VERSION}
          </p> */}
          <div
            className="formGroup"
            style={{ marginTop: 30, marginBottom: 25 }}
          >
            <label>{LABELS.USER_NAME}</label>
            <TxtBox
              placeholder={LABELS.USER_NAME}
              id={"uname"}
              defaultValue={userInfo.userName || ""}
            />
          </div>
          <div className="formGroup" style={{ marginBottom: 25 }}>
            <label>{LABELS.PASSWORD}</label>
            <TxtBox
              placeholder={LABELS.PASSWORD}
              type={showPassword ? "text" : "password"}
              id={"pwd"}
              defaultValue={userInfo.password || ""}
              onKeyPress={(e: any) => {
                if (e.keyCode === 13) {
                  submitLogin();
                }
              }}
            />
            <IconButton
              className="viewPassword"
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            >
              {showPassword ? <IconEyeOn /> : <IconEyeOff />}
            </IconButton>
            <ChkBox
              text={LABELS.REMEMBER_ME}
              id={"rememberMe"}
              checked={userInfo.userName ? true : false}
            />
          </div>
          <div
            className="formGroup"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginBottom: 30,
            }}
          >
            <Btn text={LABELS.LOGIN} onClick={submitLogin} />
          </div>
        </div>
      )}
    </div>
  );
};

export default withSnackbar(LoginPage);
