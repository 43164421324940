import { CHART_ACTIONS } from "@actions/ChartActions";

export const initState = {
  chartInfo: {},
};

const chartReducer = (state = initState, action: any) => {
  const { type, values } = action;
  if (values) {
    switch (type) {
      case CHART_ACTIONS.SET_CHART_INFO:
        return { ...state, chartInfo: values.chartInfo };
      default:
        throw new Error("Action not found..!");
    }
  }
  return state;
};

export default chartReducer;
