import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";

import LeftBar from "@components/Layouts/LeftBar";

import Reports from "@components/Reports";
import Settings from "@components/Settings";
import HomeSkeleton from "./HomeSkeleton";
import ResourcePlanning from "@components/ResourcePlanning";
import TimeSheetBooking from "@components/TimeSheetBooking";

// Masters
import Designations from "@components/Masters/Designations";
import Responsibility from "@components/Masters/Responsibility";
import ProjectTypes from "@components/Masters/ProjectTypes";
import Tasks from "@components/Masters/Tasks";
import Holidays from "@components/Masters/Holidays";
import Employees from "@components/Masters/Employees";
import Projects from "@components/Masters/Projects";
import Users from "@components/Masters/Users";

import LeavePlanner from "@components/LeavePlanner";
import LeavePlannerApprove from "@components/LeavePlannerApprove";
import PaySlip from "@components/PaySlip";
import Invoice from "@components/Invoice";
import InvoicePrint from "@components/InvoicePrint";
import TimeSheetApprove from "@components/TimeSheetApprove";
import WorkPackages from "@components/Masters/WorkPackages";
import MinutesOfMeeting from "@components/MinutesOfMeeting";

import { ApiLogin } from "@apihandlers/login";
import { CONFIG, LABELS, LOCAL_STORAGE } from "@common/config";

import "./styles/home.css";

const API_HANDLER = new ApiLogin();

const imgBanner = require("@assets/images/banner.png");

const HomePage = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState<any>({});
  const [page, setPage] = useState(LABELS.HOME);

  useEffect(() => {
    // console.log("Home: userEffect: state: ", loading);
    if (loading) {
      checkLogin();
    }
  }, [loading]);

  const checkLogin = () => {
    const args = {};
    API_HANDLER.checkLogin(args).then((res) => {
      const response: any = res.response;
      const { status } = res;
      // console.log("checkLogin: ", res);
      if (status === 200) {
        if (response.status) {
          let userInfo = localStorage.getItem(LOCAL_STORAGE.USER_INFO);
          if (userInfo) {
            userInfo = JSON.parse(userInfo);
            setUserInfo(userInfo);
          }
          setLoading(false);
          setPage(LABELS.HOME);
        } else {
          window.open("/login", "_self");
        }
      }
    });
  };

  const reportsRef: any = useRef({});

  const onSetPage = (val = "") => {
    setPage(val);
    if (val === LABELS.REPORTS) {
      reportsRef.setMenu();
    }
  };

  // console.log("page: ", page, reportsRef);

  return (
    <div
      className={"homeContainer " + (page === LABELS.HOME ? "bgBanner" : "")}
      style={{
        overflow: page === LABELS.GANTT_CHART ? "none" : "auto",
      }}
    >
      {loading ? (
        <HomeSkeleton />
      ) : (
        <React.Fragment>
          <LeftBar setPage={onSetPage} page={page} userInfo={userInfo} />
          <div className="content">
            {page === LABELS.HOME && (
              <div>
                <h4
                  className="shadowTxt fontXL"
                  style={{
                    marginTop: 35,
                    textAlign: "left",
                  }}
                >
                  {CONFIG.SITE_NAME}
                  {/* <small className="fontM">{CONFIG.VERSION}</small> */}
                </h4>
                <h4
                  className="shadowTxt fontL"
                  style={{ marginTop: 0, textAlign: "left", paddingTop: 10 }}
                >
                  Welcome {userInfo.role.name + "..!"}
                </h4>
                {/* <div style={{ width: "80%", margin: "auto" }}>
                  <img src={imgBanner} className="responsive" />
                </div> */}
              </div>
            )}

            {page === LABELS.REPORTS && (
              <Reports childRef={reportsRef} userInfo={userInfo} />
            )}

            {page === LABELS.USERS && <Users />}

            {page === LABELS.SETTINGS && <Settings userInfo={userInfo} />}

            {page === LABELS.RESOURCE_PLANNING && (
              <ResourcePlanning userInfo={userInfo} />
            )}

            {page === LABELS.TIME_SHEET && (
              <TimeSheetBooking userInfo={userInfo} />
            )}

            {page === LABELS.DESIGNATION_MASTER && (
              <Designations userInfo={userInfo} />
            )}

            {page === LABELS.RESPONSIBILITY_MASTER && (
              <Responsibility userInfo={userInfo} />
            )}

            {page === LABELS.PROJECT_TYPE_MASTER && (
              <ProjectTypes userInfo={userInfo} />
            )}

            {page === LABELS.TASK_MASTER && <Tasks userInfo={userInfo} />}

            {page === LABELS.HOLIDAY_MASTER && <Holidays userInfo={userInfo} />}

            {page === LABELS.EMPLOYEE_MASTER && (
              <Employees userInfo={userInfo} />
            )}

            {page === LABELS.PROJECTS && <Projects userInfo={userInfo} />}

            {page === LABELS.LEAVE_PLANNER && (
              <LeavePlanner userInfo={userInfo} />
            )}

            {page === LABELS.PAY_SLIP && <PaySlip userInfo={userInfo} />}

            {page === LABELS.INVOICE && <Invoice userInfo={userInfo} />}

            {page === LABELS.INVOICE_PRINT && (
              <InvoicePrint userInfo={userInfo} />
            )}

            {page === LABELS.TIME_SHEET_APPROVAL && (
              <TimeSheetApprove userInfo={userInfo} />
            )}

            {page === LABELS.LEAVE_PLANNER_APPROVE && (
              <LeavePlannerApprove userInfo={userInfo} />
            )}

            {page === LABELS.WORK_PACKAGES && (
              <WorkPackages userInfo={userInfo} />
            )}

            {page === LABELS.MINUTES_OF_MEETING && (
              <MinutesOfMeeting userInfo={userInfo} />
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default withSnackbar(HomePage);
