import React, { Component, useState } from "react";
import {
  MenuList,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Tooltip,
  ListItemButton,
  Collapse,
  List,
} from "@mui/material";
import {
  Home as IconHome,
  Menu as IconMenu,
  PowerSettingsNew as IconLogout,
  Group as IconUsers,
  FolderCopy as IconProjects,
  CloudUpload as IconUpload,
  InsertChart as IconChart,
  Dashboard as IconDashboard,
  AccountBalance as IconOrg,
  CheckBoxOutlined as IconApprove,
  Settings as IconSettings,
  PermMedia as IconProjectStatus,
  Margin as IconResourcePlanning,
  PunchClock as IconTimeSheet,
  ExpandLess,
  ExpandMore,
  Storage as IconMaster,
  TextSnippet as IconReports,
  Groups as IconMeeting,
} from "@mui/icons-material";
import { withSnackbar } from "notistack";

import ConfirmDialog from "@components/Custom/ConfirmDialog";

import {
  CONFIRMS,
  DEFAULTS,
  LABELS,
  SUCCESS,
  NOTIFY,
  ERRORS,
  LOCAL_STORAGE,
  CONFIG,
} from "@common/config";
import { ApiLogin } from "@apihandlers/login";
import { COMMON_JS } from "@common/scripts";

const logoSmall = require("@assets/images/logo.png");

const API_HANDLER_LOGIN = new ApiLogin();

const LeftBar = (props: any) => {
  const { page, setPage, userInfo } = props;
  const [leftBar, setLeftBar] = useState(false);
  const [logoutPopup, setLogoutPopup] = useState(false);
  const [showMasterMenu, setShowMasterMenu] = useState(false);

  const onUserLogout = () => {
    API_HANDLER_LOGIN.userLogout({}).then((res) => {
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.LOGOUT_SUCCESS);
        localStorage.removeItem(LOCAL_STORAGE.USER_INFO);
        localStorage.removeItem(LOCAL_STORAGE.ACCESS_TOKEN);
        setLogoutPopup(false);
        setTimeout(() => {
          window.open("/login", "_self");
        }, 500);
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.LOGOUT_FAILED);
      }
    });
  };

  const onSetLeftBar = (val: boolean) => {
    setLeftBar(val);
    if (val) {
      (document.querySelector(".content") as HTMLElement).classList.add(
        "active"
      );
    } else {
      (document.querySelector(".content") as HTMLElement).classList.remove(
        "active"
      );
      setShowMasterMenu(false);
    }
  };

  const roleId = userInfo.role.id;
  let mainMenuReports =
    roleId === CONFIG.USER_ROLES.Admin ||
    roleId === CONFIG.USER_ROLES.Director ||
    roleId === CONFIG.USER_ROLES.PlanningEngineer;
  let mainMenuMaster =
    roleId === CONFIG.USER_ROLES.Admin ||
    roleId === CONFIG.USER_ROLES.Director ||
    roleId === CONFIG.USER_ROLES.PlanningEngineer;
  let mainMenuTimeSheet =
    roleId === CONFIG.USER_ROLES.Admin ||
    roleId === CONFIG.USER_ROLES.Director ||
    roleId === CONFIG.USER_ROLES.QuantitySurveyor ||
    roleId === CONFIG.USER_ROLES.SeniorQuantitySurveyor ||
    roleId === CONFIG.USER_ROLES.PlanningEngineer ||
    roleId === CONFIG.USER_ROLES.HR;
  let mainMenuTimeSheetApprove =
    roleId === CONFIG.USER_ROLES.Admin ||
    roleId === CONFIG.USER_ROLES.Director ||
    roleId === CONFIG.USER_ROLES.PlanningEngineer ||
    roleId === CONFIG.USER_ROLES.SeniorQuantitySurveyor;
  // let mainMenuResourcePlanning = roleId === CONFIG.USER_ROLES.Admin;
  let mainMenuResourcePlanning =
    roleId === CONFIG.USER_ROLES.Admin ||
    roleId === CONFIG.USER_ROLES.Director ||
    roleId === CONFIG.USER_ROLES.PlanningEngineer ||
    roleId === CONFIG.USER_ROLES.QuantitySurveyor ||
    roleId === CONFIG.USER_ROLES.SeniorQuantitySurveyor ||
    roleId === CONFIG.USER_ROLES.HR;
  let mainMenuLeavePlanner =
    roleId === CONFIG.USER_ROLES.Admin ||
    roleId === CONFIG.USER_ROLES.Director ||
    roleId === CONFIG.USER_ROLES.PlanningEngineer ||
    roleId === CONFIG.USER_ROLES.QuantitySurveyor ||
    roleId === CONFIG.USER_ROLES.SeniorQuantitySurveyor ||
    roleId === CONFIG.USER_ROLES.HR;
  let mainMenuLeavePlannerApprove =
    roleId === CONFIG.USER_ROLES.Admin ||
    roleId === CONFIG.USER_ROLES.Director ||
    roleId === CONFIG.USER_ROLES.HR;
  let mainMenuMinsOfMeeting = true;
  let mainMenuPaySlip =
    roleId === CONFIG.USER_ROLES.Admin ||
    roleId === CONFIG.USER_ROLES.Director ||
    roleId === CONFIG.USER_ROLES.HR;
  let mainMenuInvoice =
    roleId === CONFIG.USER_ROLES.Admin || roleId === CONFIG.USER_ROLES.Director;
  let mainMenuInvoicePrint =
    roleId === CONFIG.USER_ROLES.Admin || roleId === CONFIG.USER_ROLES.Director;

  let menuHolidayMaster = false;
  let menuTaskMaster = false;
  let menuProjectTypeMaster = false;
  let menuProjects = false;
  let menuResponsibility = false;
  let menuWorkPackages = false;
  let menuDesignationMaster = false;
  let menuEmpMaster = false;
  if (roleId === CONFIG.USER_ROLES.PlanningEngineer) {
    menuProjects = true;
  } else {
    menuHolidayMaster = true;
    menuTaskMaster = true;
    menuProjectTypeMaster = true;
    menuProjects = true;
    menuResponsibility = true;
    menuWorkPackages = true;
    menuDesignationMaster = true;
    menuEmpMaster = true;
  }

  let menuAdmin = roleId === CONFIG.USER_ROLES.Admin;

  return (
    <div className={"leftBar " + (leftBar ? "active" : "")}>
      <MenuList className="leftMenuWrapper">
        <MenuItem className={"leftMenu"} onClick={() => onSetLeftBar(!leftBar)}>
          <ListItemIcon>
            <IconMenu fontSize="small" />
          </ListItemIcon>
          <div>
            <h4>
              {CONFIG.SITE_NAME} <small>{CONFIG.VERSION}</small>
            </h4>
            {/* <img src={logoSmall} className="responsive" /> */}
          </div>
        </MenuItem>

        <MenuItem
          className={
            "leftMenu " +
            (page === LABELS.HOME || page === LABELS.MENU || page === ""
              ? "active"
              : "")
          }
          onClick={() => setPage(LABELS.HOME)}
        >
          <Tooltip title={LABELS.HOME} placement={"right-start"}>
            <div>
              <ListItemIcon>
                <IconHome fontSize="small" />
              </ListItemIcon>
              <ListItemText className="menuText">{LABELS.HOME}</ListItemText>
            </div>
          </Tooltip>
        </MenuItem>

        {mainMenuReports && (
          <MenuItem
            className={"leftMenu " + (page === LABELS.REPORTS ? "active" : "")}
            onClick={() => setPage(LABELS.REPORTS)}
          >
            <Tooltip title={LABELS.REPORTS} placement={"right-start"}>
              <div>
                <ListItemIcon>
                  <IconReports fontSize="small" />
                </ListItemIcon>
                <ListItemText className="menuText">
                  {LABELS.REPORTS}
                </ListItemText>
              </div>
            </Tooltip>
          </MenuItem>
        )}

        {mainMenuMaster && (
          <ListItemButton
            onClick={() => {
              setShowMasterMenu(!showMasterMenu);
            }}
            style={{
              paddingLeft: 20,
            }}
          >
            <ListItemIcon
              style={{
                minWidth: 26,
                position: "relative",
                left: leftBar ? 0 : -5,
              }}
            >
              <IconMaster style={{ fontSize: 18 }} />
            </ListItemIcon>
            {leftBar && (
              <ListItemText
                className="menuText"
                primary="Masters"
                style={{
                  textAlign: "left",
                  color: "#fff",
                  marginLeft: 18,
                }}
              />
            )}
            {leftBar && (showMasterMenu ? <ExpandLess /> : <ExpandMore />)}
          </ListItemButton>
        )}
        <Collapse in={showMasterMenu} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {menuHolidayMaster && (
              <MenuItem
                className={
                  "leftMenu " + (page === LABELS.HOLIDAY_MASTER ? "active" : "")
                }
                onClick={() => setPage(LABELS.HOLIDAY_MASTER)}
              >
                <Tooltip
                  title={LABELS.HOLIDAY_MASTER}
                  placement={"right-start"}
                >
                  <div>
                    <ListItemIcon></ListItemIcon>
                    <ListItemText className="menuText">
                      {LABELS.HOLIDAY_MASTER}
                    </ListItemText>
                  </div>
                </Tooltip>
              </MenuItem>
            )}

            {menuTaskMaster && (
              <MenuItem
                className={
                  "leftMenu " + (page === LABELS.TASK_MASTER ? "active" : "")
                }
                onClick={() => setPage(LABELS.TASK_MASTER)}
              >
                <Tooltip title={LABELS.TASK_MASTER} placement={"right-start"}>
                  <div>
                    <ListItemIcon></ListItemIcon>
                    <ListItemText className="menuText">
                      {LABELS.TASK_MASTER}
                    </ListItemText>
                  </div>
                </Tooltip>
              </MenuItem>
            )}

            {menuProjectTypeMaster && (
              <MenuItem
                className={
                  "leftMenu " +
                  (page === LABELS.PROJECT_TYPE_MASTER ? "active" : "")
                }
                onClick={() => setPage(LABELS.PROJECT_TYPE_MASTER)}
              >
                <Tooltip
                  title={LABELS.PROJECT_TYPE_MASTER}
                  placement={"right-start"}
                >
                  <div>
                    <ListItemIcon></ListItemIcon>
                    <ListItemText className="menuText">
                      {LABELS.PROJECT_TYPE_MASTER}
                    </ListItemText>
                  </div>
                </Tooltip>
              </MenuItem>
            )}

            {menuProjects && (
              <MenuItem
                className={
                  "leftMenu " + (page === LABELS.PROJECTS ? "active" : "")
                }
                onClick={() => setPage(LABELS.PROJECTS)}
              >
                <Tooltip title={LABELS.PROJECTS} placement={"right-start"}>
                  <div>
                    <ListItemIcon></ListItemIcon>
                    <ListItemText className="menuText">
                      {LABELS.PROJECTS}
                    </ListItemText>
                  </div>
                </Tooltip>
              </MenuItem>
            )}

            {menuResponsibility && (
              <MenuItem
                className={
                  "leftMenu " +
                  (page === LABELS.RESPONSIBILITY_MASTER ? "active" : "")
                }
                onClick={() => setPage(LABELS.RESPONSIBILITY_MASTER)}
              >
                <Tooltip
                  title={LABELS.RESPONSIBILITY_MASTER}
                  placement={"right-start"}
                >
                  <div>
                    <ListItemIcon></ListItemIcon>
                    <ListItemText className="menuText">
                      {LABELS.RESPONSIBILITY_MASTER}
                    </ListItemText>
                  </div>
                </Tooltip>
              </MenuItem>
            )}

            {menuWorkPackages && (
              <MenuItem
                className={
                  "leftMenu " + (page === LABELS.WORK_PACKAGES ? "active" : "")
                }
                onClick={() => setPage(LABELS.WORK_PACKAGES)}
              >
                <Tooltip title={LABELS.WORK_PACKAGES} placement={"right-start"}>
                  <div>
                    <ListItemIcon></ListItemIcon>
                    <ListItemText className="menuText">
                      {LABELS.WORK_PACKAGES}
                    </ListItemText>
                  </div>
                </Tooltip>
              </MenuItem>
            )}

            {menuDesignationMaster && (
              <MenuItem
                className={
                  "leftMenu " +
                  (page === LABELS.DESIGNATION_MASTER ? "active" : "")
                }
                onClick={() => setPage(LABELS.DESIGNATION_MASTER)}
              >
                <Tooltip
                  title={LABELS.DESIGNATION_MASTER}
                  placement={"right-start"}
                >
                  <div>
                    <ListItemIcon></ListItemIcon>
                    <ListItemText className="menuText">
                      {LABELS.DESIGNATION_MASTER}
                    </ListItemText>
                  </div>
                </Tooltip>
              </MenuItem>
            )}

            {menuEmpMaster && (
              <MenuItem
                className={
                  "leftMenu " +
                  (page === LABELS.EMPLOYEE_MASTER ? "active" : "")
                }
                onClick={() => setPage(LABELS.EMPLOYEE_MASTER)}
              >
                <Tooltip
                  title={LABELS.EMPLOYEE_MASTER}
                  placement={"right-start"}
                >
                  <div>
                    <ListItemIcon></ListItemIcon>
                    <ListItemText className="menuText">
                      {LABELS.EMPLOYEE_MASTER}
                    </ListItemText>
                  </div>
                </Tooltip>
              </MenuItem>
            )}

            {menuAdmin && (
              <MenuItem
                className={
                  "leftMenu " + (page === LABELS.USERS ? "active" : "")
                }
                onClick={() => setPage(LABELS.USERS)}
              >
                <Tooltip title={LABELS.USERS} placement={"right-start"}>
                  <div>
                    <ListItemIcon></ListItemIcon>
                    <ListItemText className="menuText">
                      {LABELS.USERS}
                    </ListItemText>
                  </div>
                </Tooltip>
              </MenuItem>
            )}
          </List>
        </Collapse>

        {mainMenuTimeSheet && (
          <MenuItem
            className={
              "leftMenu " + (page === LABELS.TIME_SHEET ? "active" : "")
            }
            onClick={() => setPage(LABELS.TIME_SHEET)}
          >
            <Tooltip title={LABELS.TIME_SHEET} placement={"right-start"}>
              <div>
                <ListItemIcon>
                  <IconTimeSheet fontSize="small" />
                </ListItemIcon>
                <ListItemText className="menuText">
                  {LABELS.TIME_SHEET}
                </ListItemText>
              </div>
            </Tooltip>
          </MenuItem>
        )}

        {mainMenuTimeSheetApprove && (
          <MenuItem
            className={
              "leftMenu " +
              (page === LABELS.TIME_SHEET_APPROVAL ? "active" : "")
            }
            onClick={() => setPage(LABELS.TIME_SHEET_APPROVAL)}
          >
            <Tooltip
              title={LABELS.TIME_SHEET_APPROVAL}
              placement={"right-start"}
            >
              <div>
                <ListItemIcon>
                  <IconTimeSheet fontSize="small" />
                </ListItemIcon>
                <ListItemText className="menuText">
                  {LABELS.TIME_SHEET_APPROVAL}
                </ListItemText>
              </div>
            </Tooltip>
          </MenuItem>
        )}

        {mainMenuResourcePlanning && (
          <MenuItem
            className={
              "leftMenu " + (page === LABELS.RESOURCE_PLANNING ? "active" : "")
            }
            onClick={() => setPage(LABELS.RESOURCE_PLANNING)}
          >
            <Tooltip title={LABELS.RESOURCE_PLANNING} placement={"right-start"}>
              <div>
                <ListItemIcon>
                  <IconResourcePlanning fontSize="small" />
                </ListItemIcon>
                <ListItemText className="menuText">
                  {LABELS.RESOURCE_PLANNING}
                </ListItemText>
              </div>
            </Tooltip>
          </MenuItem>
        )}

        {mainMenuLeavePlanner && (
          <MenuItem
            className={
              "leftMenu " + (page === LABELS.LEAVE_PLANNER ? "active" : "")
            }
            onClick={() => setPage(LABELS.LEAVE_PLANNER)}
          >
            <Tooltip title={LABELS.LEAVE_PLANNER} placement={"right-start"}>
              <div>
                <ListItemIcon>
                  <IconUsers fontSize="small" />
                </ListItemIcon>
                <ListItemText className="menuText">
                  {LABELS.LEAVE_PLANNER}
                </ListItemText>
              </div>
            </Tooltip>
          </MenuItem>
        )}

        {mainMenuMinsOfMeeting && (
          <MenuItem
            className={
              "leftMenu " + (page === LABELS.MINUTES_OF_MEETING ? "active" : "")
            }
            onClick={() => setPage(LABELS.MINUTES_OF_MEETING)}
          >
            <Tooltip
              title={LABELS.MINUTES_OF_MEETING}
              placement={"right-start"}
            >
              <div>
                <ListItemIcon>
                  <IconMeeting fontSize="small" />
                </ListItemIcon>
                <ListItemText className="menuText">
                  {LABELS.MINUTES_OF_MEETING}
                </ListItemText>
              </div>
            </Tooltip>
          </MenuItem>
        )}

        {mainMenuLeavePlannerApprove && (
          <MenuItem
            className={
              "leftMenu " +
              (page === LABELS.LEAVE_PLANNER_APPROVE ? "active" : "")
            }
            onClick={() => setPage(LABELS.LEAVE_PLANNER_APPROVE)}
          >
            <Tooltip
              title={LABELS.LEAVE_PLANNER_APPROVE}
              placement={"right-start"}
            >
              <div>
                <ListItemIcon>
                  <IconUsers fontSize="small" />
                </ListItemIcon>
                <ListItemText className="menuText">
                  {LABELS.LEAVE_PLANNER_APPROVE}
                </ListItemText>
              </div>
            </Tooltip>
          </MenuItem>
        )}

        {mainMenuPaySlip && (
          <MenuItem
            className={"leftMenu " + (page === LABELS.PAY_SLIP ? "active" : "")}
            onClick={() => setPage(LABELS.PAY_SLIP)}
          >
            <Tooltip title={LABELS.PAY_SLIP} placement={"right-start"}>
              <div>
                <ListItemIcon>
                  <IconReports fontSize="small" />
                </ListItemIcon>
                <ListItemText className="menuText">
                  {LABELS.PAY_SLIP}
                </ListItemText>
              </div>
            </Tooltip>
          </MenuItem>
        )}

        {mainMenuInvoice && (
          <MenuItem
            className={"leftMenu " + (page === LABELS.INVOICE ? "active" : "")}
            onClick={() => setPage(LABELS.INVOICE)}
          >
            <Tooltip title={LABELS.INVOICE} placement={"right-start"}>
              <div>
                <ListItemIcon>
                  <IconReports fontSize="small" />
                </ListItemIcon>
                <ListItemText className="menuText">
                  {LABELS.INVOICE}
                </ListItemText>
              </div>
            </Tooltip>
          </MenuItem>
        )}

        {mainMenuInvoicePrint && (
          <MenuItem
            className={
              "leftMenu " + (page === LABELS.INVOICE_PRINT ? "active" : "")
            }
            onClick={() => setPage(LABELS.INVOICE_PRINT)}
          >
            <Tooltip title={LABELS.INVOICE_PRINT} placement={"right-start"}>
              <div>
                <ListItemIcon>
                  <IconReports fontSize="small" />
                </ListItemIcon>
                <ListItemText className="menuText">
                  {LABELS.INVOICE_PRINT}
                </ListItemText>
              </div>
            </Tooltip>
          </MenuItem>
        )}

        <MenuItem
          className={"leftMenu " + (page === LABELS.SETTINGS ? "active" : "")}
          onClick={() => setPage(LABELS.SETTINGS)}
        >
          <Tooltip title={LABELS.SETTINGS} placement={"right-start"}>
            <div>
              <ListItemIcon>
                <IconSettings fontSize="small" />
              </ListItemIcon>
              <ListItemText className="menuText">
                {LABELS.SETTINGS}
              </ListItemText>
            </div>
          </Tooltip>
        </MenuItem>

        <MenuItem
          className={"leftMenu " + (page === LABELS.LOGOUT ? "active" : "")}
          onClick={() => setLogoutPopup(!logoutPopup)}
        >
          <Tooltip title={LABELS.LOGOUT} placement={"right-start"}>
            <div>
              <ListItemIcon>
                <IconLogout fontSize="small" />
              </ListItemIcon>
              <ListItemText className="menuText">{LABELS.LOGOUT}</ListItemText>
            </div>
          </Tooltip>
        </MenuItem>
      </MenuList>

      <ConfirmDialog
        open={logoutPopup}
        title={LABELS.CONFIRM}
        msg={CONFIRMS.SURE_TO_LOGOUT}
        size={DEFAULTS.DIALOG_SIZE}
        onClose={() => setLogoutPopup(false)}
        onOk={onUserLogout}
      />
    </div>
  );
};

export default withSnackbar(LeftBar);
