import React, { useEffect, useRef, useState } from "react";
import DataGrid, {
  Column,
  Editing,
  Paging,
  Scrolling,
  GroupPanel,
  SearchPanel,
  Grouping,
  Selection,
} from "devextreme-react/data-grid";
import { withSnackbar } from "notistack";

import "react-quill/dist/quill.snow.css";

import TxtBox from "@components/Custom/TxtBox";
import Btn from "@components/Custom/Btn";
import Loader from "@components/Custom/Loader";
import AutoComplete from "@components/Custom/AutoComplete";

import { LABELS, NOTIFY, SUCCESS } from "@common/config";
import { ApiMasters } from "@apihandlers/masters";
import { ApiPrint } from "@apihandlers/print";
import { ApiHome } from "@apihandlers/home";

import { COMMON_JS } from "@common/scripts";

const API_HANDLER_MASTERS = new ApiMasters();
const API_HANDLER_PRINT = new ApiPrint();
const API_HANDLER_HOME = new ApiHome();

let _timer = null;

const thumbnail = require("@assets/images/employee.png");

const Invoice = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState<any>([]);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [selectedCvRow, setSelectedCvRow] = useState<any>(null);
  const [clientList, setClientList] = useState([]);
  const [cvDialog, setCvDialog] = useState(false);
  const [selectedProject, setSelectedProject] = useState<any>({});
  const [invoiceNumber, setInvoiceNumber] = useState("");

  const [newRowPosition, setNewRowPosition] = React.useState("viewportTop");
  const [changes, setChanges] = React.useState([]);
  const [editRowKey, setEditRowKey] = React.useState(null);
  const [hasChanges, setHasChanges] = useState(false);
  const [basic, setBasic] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalTax, setTotalTax] = useState(0);

  useEffect(() => {
    if (loading) {
      getClients();
    }
  }, [loading]);

  const getClients = () => {
    const args = {};
    API_HANDLER_MASTERS.getClients(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.clientName,
          };
        });
        getInvoiceNumber(list);
      }
    });
  };

  const getInvoiceNumber = (list = []) => {
    const args = {};
    API_HANDLER_HOME.getInvoiceNumber(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setClientList(list);
        setInvoiceNumber(response.invoiceNumber);
      }
    });
  };

  const onChangeClient = (e: any, { id }) => {
    const args = {
      clientName: id,
    };
    API_HANDLER_MASTERS.getProjectByClientName(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setSelectedProject(response);
      }
    });
  };

  const getRows = () => {
    if (!selectedProject.projectId) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, "Please select client..!");
      return;
    }
    const invoiceDt = (document.getElementById("invoiceDt") as HTMLInputElement)
      .value;
    const startDt = (document.getElementById("startDt") as HTMLInputElement)
      .value;
    const finishDt = (document.getElementById("finishDt") as HTMLInputElement)
      .value;
    if (!invoiceDt) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please enter invoice date..!"
      );
      return;
    }
    if (!startDt) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, "Please enter start date..!");
      return;
    }
    if (!finishDt) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please enter finish date..!"
      );
      return;
    }
    const args = {
      projectId: selectedProject.id,
      invoiceDt,
      startDt,
      finishDt,
    };
    API_HANDLER_HOME.getInvoiceTasks(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records;
        for (let i = 0; i < response.records.length; i++) {
          list[i]["slNo"] = i + 1;
        }
        setRows(list);
        setFilteredRows([]);
        setLoading(false);
      }
    });
  };

  const onEditRow = (row: any) => {
    setSelectedRow(row);
    setManageDialog(true);
    // Get Image
    if (row.fileId) {
      const args = { fileId: row.fileId };
      API_HANDLER_HOME.getFile(args).then((res: any) => {
        let base64 = "data:" + row.mimeType + ";base64, " + res;
        fetch(base64).then((r) => {
          r.blob().then((data) => {
            let objUrl = window.URL.createObjectURL(data);
            // console.log("objUrl: ", objUrl);
            (document.getElementById("profilePic") as HTMLElement).setAttribute(
              "src",
              objUrl
            );
          });
        });
      });
    }
  };

  const onAddCv = (row: any) => {
    setCvDialog(true);
    setSelectedCvRow(null);
    // Get CV Info
    let args = { empId: row.id };
    API_HANDLER_MASTERS.getCv(args).then((res) => {
      if (res.status === 200) {
        let emp: any = res.response;
        if (!emp.empId) {
          emp = {
            empId: row.id,
            empName: row.empName,
          };
        }
        setSelectedCvRow(emp);
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, "Error: " + res.error);
      }
    });
  };

  const onPrintPaySlip = (row: any) => {
    const args = {
      id: row.id,
    };
    API_HANDLER_PRINT.printPaySlip(args)
      .then((res) => {
        if (res) {
          let base64 = "data:application/pdf;base64, " + res;
          fetch(base64).then((r) => {
            r.blob().then((data) => {
              let objUrl = window.URL.createObjectURL(data);
              window.open(objUrl, "_blank");
              window.URL.revokeObjectURL(objUrl);
            });
          });
        } else {
          COMMON_JS.showNotify(
            props,
            NOTIFY.ERROR,
            "Print Pay Slip Not Found..!"
          );
        }
      })
      .catch((err) => {
        COMMON_JS.showNotify(
          props,
          NOTIFY.ERROR,
          "Print Pay Slip Not Found..!"
        );
      });
  };

  const manageRecord = () => {
    const clientName = clientRef.current.selectedValue.id;
    const tax = taxRef.current.selectedValue.id;
    const invoiceDt = (
      document.getElementById("invoiceDt") as HTMLInputElement
    ).value.trim();
    const startDt = (
      document.getElementById("startDt") as HTMLInputElement
    ).value.trim();
    const finishDt = (
      document.getElementById("finishDt") as HTMLInputElement
    ).value.trim();
    const dueDt = (
      document.getElementById("dueDt") as HTMLInputElement
    ).value.trim();
    const terms = (
      document.getElementById("terms") as HTMLInputElement
    ).value.trim();
    const notes = (
      document.getElementById("notes") as HTMLInputElement
    ).value.trim();

    // Validations
    if (!invoiceDt) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please select invoice date..!"
      );
      return;
    }
    if (!startDt) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please select start date..!"
      );
      return;
    }
    if (!finishDt) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please select finish date..!"
      );
      return;
    }
    if (!selectedProject.id) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, "Please select project..!");
      return;
    }
    if (!dueDt) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, "Please select due date..!");
      return;
    }
    if (rows.length === 0) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, "No Tasks Found..!");
      return;
    }
    const finalRows = [];
    for (let i = 0; i < rows.length; i++) {
      let row = selectedRows.filter((r: any) => {
        return r.ID === rows[i].ID;
      });
      if (row.length > 0) {
        finalRows.push(rows[i]);
      }
    }
    if (finalRows.length === 0) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please select minimum one task..!"
      );
      return;
    }
    for (let f = 0; f < finalRows.length; f++) {
      if (finalRows[f].amount === undefined) {
        COMMON_JS.showNotify(
          props,
          NOTIFY.WARNING,
          "Please enter billing qty details for the task: " +
            finalRows[f].taskName +
            "..!"
        );
        return;
      }
    }
    const args = {
      invoiceNumber,
      clientName,
      invoiceDt,
      startDt,
      finishDt,
      projectId: selectedProject.id,
      rows: finalRows,
      basic,
      totalTax,
      total,
      tax,
      dueDt,
      terms,
      notes,
    };
    API_HANDLER_HOME.saveInvoice(args).then((res) => {
      // console.log("saveInvoice: ", args, res);
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
        reloadGrid();
        setBasic(0);
        setTotal(0);
        setTotalTax(0);
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, "Error: " + res.error);
      }
    });
  };

  const reloadGrid = () => {
    setRows(null);
    setTimeout(() => {
      getRows();
    }, 2000);
  };

  const onRowInserted = React.useCallback((e: any) => {
    // console.log("onRowInserted: ", e);
    e.component.navigateToRow(e.key);
  }, []);

  const onSaving = React.useCallback(
    (e: any) => {
      e.cancel = true;
      if (changes[0] || changes[0].data) {
        let rowId = changes[0].key;
        let saveType = changes[0].type;
        let chng = changes;
        if (saveType === "update") {
          let changesRow = rows.filter((r: any) => {
            return r.ID === rowId;
          });
          // console.log("changesRow: ", changesRow, chng);
          if (changesRow.length > 0) {
            for (let key in changesRow[0]) {
              if (key !== "ID") {
                if (!(key in chng[0]["data"])) {
                  chng[0]["data"][key] = changesRow[0][key];
                }
              }
            }
          }
        }

        let tmpRows = rows;
        let data = changes[0].data;
        // Existing Record Update
        for (let indx in rows) {
          if (tmpRows[indx]["ID"] === rowId) {
            for (let key in data) {
              tmpRows[indx][key] = data[key];
            }
            let rate = 0;
            if (tmpRows[indx]["rate"]) {
              rate = tmpRows[indx]["rate"];
            } else {
              tmpRows[indx]["rate"] = rate;
            }
            tmpRows[indx]["amount"] =
              Number(rate) * Number(tmpRows[indx]["qty"]);
            break;
          }
        }
        setRows(tmpRows);
        setChanges([]);
        setEditRowKey(null);
        setHasChanges(true);
        // Update total
        let total = 0;
        for (let i = 0; i < tmpRows.length; i++) {
          let amount = 0;
          if (tmpRows[i]["amount"] != undefined) {
            amount = tmpRows[i]["amount"];
          }
          let sum = Number(amount);
          total += sum;
        }
        setBasic(total);
        // e.promise = saveChange(dispatch, e.changes[0]);
      }
    },
    [changes, rows]
  );

  const onChangeTax = (e, { id }) => {
    let totalTax = basic * Number(id);
    setTotalTax(totalTax);
    setTotal(basic + totalTax);
    // console.log("onChangeTax: ", totalTax);
  };

  const onSelectionChange = (e: any) => {
    let row = e.selectedRowsData.find((d: any) => {
      return e.currentSelectedRowKeys.indexOf(d.ID) > -1;
    });
    // console.log("onSelectionChange: ", e, row);
    if (row.amount === undefined) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please enter Billing Qty. details..!"
      );
    }
    setSelectedRows(e.selectedRowsData);
  };

  const clientRef: any = useRef(null);
  const gridRef: any = useRef(null);
  const taxRef: any = useRef(null);

  // console.log("Invoice: selectedProject:", selectedProject);

  return (
    <div>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li className="active">{LABELS.INVOICE}</li>
        </ul>
        <h4>{LABELS.INVOICE}</h4>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          marginTop: 10,
        }}
      >
        <div style={{ width: 400 }}>
          <div
            className="formGroup"
            style={{
              width: 150,
            }}
          >
            <label>{LABELS.DATE_OF_INVOICE}</label>
            <TxtBox
              id={"invoiceDt"}
              placeholder={LABELS.DATE_OF_INVOICE}
              type={"date"}
            />
          </div>
          <div
            className="formGroup"
            style={{
              width: 300,
            }}
          >
            <label>{LABELS.CLIENT_NAME}</label>
            <AutoComplete
              id={"clientList"}
              list={clientList}
              childRef={clientRef}
              onChange={onChangeClient}
            />
          </div>
          <div
            className="formGroup"
            style={{
              display: "flex",
              flexDirection: "column",
              width: 300,
            }}
          >
            <label
              style={{
                marginTop: 5,
                marginBottom: 15,
              }}
            >
              {"Project ID: " +
                (selectedProject.projectId ? selectedProject.projectId : "")}
            </label>
            <label
              style={{
                marginBottom: 10,
              }}
            >
              {"Project Name: " +
                (selectedProject.projectName
                  ? selectedProject.projectName
                  : "")}
            </label>
            <label
              style={{
                marginBottom: 5,
              }}
            >
              {"Invoice Number: " + invoiceNumber}
            </label>
          </div>
        </div>

        <div style={{ width: "50%" }}>
          <div
            className="formGroup"
            style={{
              width: 150,
            }}
          >
            <label>{LABELS.START_DATE}</label>
            <TxtBox
              id={"startDt"}
              placeholder={LABELS.START_DATE}
              type={"date"}
            />
          </div>
          <div
            className="formGroup"
            style={{
              width: 150,
            }}
          >
            <label>{LABELS.FINISH_DATE}</label>
            <TxtBox
              id={"finishDt"}
              placeholder={LABELS.FINISH_DATE}
              type={"date"}
            />
          </div>
        </div>

        <div
          className="formGroup"
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginTop: 0,
            width: "100%",
          }}
        >
          <div
            style={{
              width: "45%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Btn text={LABELS.LOAD} onClick={getRows} />
          </div>
        </div>
      </div>

      <div
        className="grid"
        style={{
          width: "98%",
          height: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        {rows ? (
          <DataGrid
            id="gridContainer"
            ref={gridRef}
            dataSource={rows}
            keyExpr="ID"
            showBorders={true}
            allowColumnResizing={true}
            columnMinWidth={150}
            columnAutoWidth={true}
            onRowInserted={onRowInserted}
            onSaving={onSaving}
            onSelectionChanged={onSelectionChange}
          >
            <Scrolling columnRenderingMode="virtual" />
            <Paging enabled={true} />
            <Editing
              mode="row"
              allowAdding={true}
              allowDeleting={true}
              allowUpdating={true}
              confirmDelete={true}
              useIcons={true}
              newRowPosition={newRowPosition}
              changes={changes}
              onChangesChange={setChanges}
              editRowKey={editRowKey}
              onEditRowKeyChange={setEditRowKey}
            />
            <Selection
              mode="multiple"
              selectAllMode={"allPages"}
              showCheckBoxesMode={"onClick"}
            />
            <Column
              dataField="taskName"
              caption="Description"
              allowEditing={false}
            />
            <Column dataField="sum" caption="QTY (TS)" allowEditing={false} />
            <Column dataField="qty" caption="QTY (Billing)" minWidth={50} />
            <Column dataField="rate" caption="Rate" minWidth={50} />
            <Column
              dataField="amount"
              caption="Amount"
              minWidth={50}
              allowEditing={false}
            />
            <GroupPanel visible={true} />
            <SearchPanel visible={true} />
            <Grouping autoExpandAll={true} />
          </DataGrid>
        ) : (
          <Loader />
        )}
      </div>

      {rows && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div>
            <h4
              style={{
                marginBottom: 10,
              }}
            >
              {"Basic: " + basic}
            </h4>
          </div>
          <div
            className="formGroup"
            style={{
              width: "30%",
              marginBottom: 6,
            }}
          >
            <label>{LABELS.SELECT_TAX}</label>
            <AutoComplete
              id={"tax"}
              list={[
                {
                  id: "0.21",
                  label: "21%",
                },
                {
                  id: "0.23",
                  label: "23%",
                },
              ]}
              childRef={taxRef}
              onChange={onChangeTax}
            />
          </div>
          <div
            style={{
              marginTop: 0,
            }}
          >
            <h4 style={{ marginTop: 8, marginBottom: 0 }}>
              {"Total Tax: " + totalTax}
            </h4>
            <h4 style={{ marginTop: 12, marginBottom: 10 }}>
              {"Total: " + total}
            </h4>
          </div>
          <div
            className="formGroup"
            style={{
              width: "30%",
            }}
          >
            <label>{LABELS.DUE_DATE}</label>
            <TxtBox id={"dueDt"} placeholder={LABELS.DUE_DATE} type={"date"} />
          </div>
          <div
            className="formGroup"
            style={{
              width: "30%",
            }}
          >
            <label>{LABELS.TERMS}</label>
            <TxtBox id={"terms"} placeholder={LABELS.TERMS} />
          </div>
          <div
            className="formGroup"
            style={{
              width: "30%",
            }}
          >
            <label>{LABELS.NOTES}</label>
            <TxtBox id={"notes"} placeholder={LABELS.NOTES} />
          </div>
        </div>
      )}

      {rows && (
        <div style={{ marginTop: 0, marginBottom: 45 }}>
          <Btn text={LABELS.SAVE} onClick={manageRecord} />
        </div>
      )}
    </div>
  );
};

export default withSnackbar(Invoice);
