import React, { useEffect, useRef, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import {
  Replay as IconReload,
  AddBox as IconAdd,
  Edit as IconEdit,
  LocalPrintshop as IconPrint,
  Article as IconCv,
} from "@mui/icons-material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import TxtBox from "@components/Custom/TxtBox";
import UiDialog from "@components/Custom/UiDialog";
import Btn from "@components/Custom/Btn";
import SwitchBtn from "@components/Custom/SwitchBtn";
import Loader from "@components/Custom/Loader";
import AutoComplete from "@components/Custom/AutoComplete";

import {
  COLORS,
  ERRORS,
  LABELS,
  NOTIFY,
  SUCCESS,
  WARNINGS,
} from "@common/config";
import { ApiMasters } from "@apihandlers/masters";
import { ApiPrint } from "@apihandlers/print";
import { ApiHome } from "@apihandlers/home";

import { COMMON_JS } from "@common/scripts";

const API_HANDLER_MASTERS = new ApiMasters();
const API_HANDLER_PRINT = new ApiPrint();
const API_HANDLER_HOME = new ApiHome();

let _timer = null;

const thumbnail = require("@assets/images/employee.png");

const PaySlip = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState<any>(null);
  const [filteredRows, setFilteredRows] = useState([]);
  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [selectedCvRow, setSelectedCvRow] = useState<any>(null);
  const [empList, setEmpList] = useState([]);
  const [cvDialog, setCvDialog] = useState(false);
  const [payslipInfo, setPayslipInfo] = useState<any>({});

  const [profession, setProfession] = useState("");
  const [qualification, setQualification] = useState("");
  const [course, setCourse] = useState("");
  const [keySkills, setKeySkills] = useState("");
  const [countries, setCountries] = useState("");
  const [experience, setExperience] = useState("");
  const [recentProfHistory, setRecentProfHistory] = useState("");
  const [selectedProfHistory, setSelectedProfHistory] = useState("");

  useEffect(() => {
    if (loading) {
      getEmpList();
    }
  }, [loading]);

  const getEmpList = () => {
    const args = {};
    API_HANDLER_MASTERS.getEmployeeMaster(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.empName,
          };
        });
        setEmpList(list);
        getRows();
      }
    });
  };

  const getRows = () => {
    const args = {};
    API_HANDLER_HOME.getPaySlips(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records;
        for (let i = 0; i < response.records.length; i++) {
          list[i]["slNo"] = i + 1;
        }
        setRows(list);
        setFilteredRows([]);
        setLoading(false);
      }
    });
  };

  const onEditRow = (row: any) => {
    setSelectedRow(row);
    setManageDialog(true);
    // Get Image
    if (row.fileId) {
      const args = { fileId: row.fileId };
      API_HANDLER_HOME.getFile(args).then((res: any) => {
        let base64 = "data:" + row.mimeType + ";base64, " + res;
        fetch(base64).then((r) => {
          r.blob().then((data) => {
            let objUrl = window.URL.createObjectURL(data);
            // console.log("objUrl: ", objUrl);
            (document.getElementById("profilePic") as HTMLElement).setAttribute(
              "src",
              objUrl
            );
          });
        });
      });
    }
  };

  const onAddCv = (row: any) => {
    setCvDialog(true);
    setSelectedCvRow(null);
    // Get CV Info
    let args = { empId: row.id };
    API_HANDLER_MASTERS.getCv(args).then((res) => {
      if (res.status === 200) {
        let emp: any = res.response;
        if (!emp.empId) {
          emp = {
            empId: row.id,
            empName: row.empName,
          };
        }
        setSelectedCvRow(emp);
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, "Error: " + res.error);
      }
    });
  };

  const onPrintPaySlip = (row: any) => {
    const args = {
      id: row.id,
    };
    API_HANDLER_PRINT.printPaySlip(args)
      .then((res) => {
        if (res) {
          let base64 = "data:application/pdf;base64, " + res;
          fetch(base64).then((r) => {
            r.blob().then((data) => {
              let objUrl = window.URL.createObjectURL(data);
              window.open(objUrl, "_blank");
              window.URL.revokeObjectURL(objUrl);
            });
          });
        } else {
          COMMON_JS.showNotify(
            props,
            NOTIFY.ERROR,
            "Print Pay Slip Not Found..!"
          );
        }
      })
      .catch((err) => {
        COMMON_JS.showNotify(
          props,
          NOTIFY.ERROR,
          "Print Pay Slip Not Found..!"
        );
      });
  };

  const columns: GridColDef[] = [
    {
      field: "slNo",
      headerName: "Sl No.",
      width: 100,
      type: "number",
      align: "center",
      headerAlign: "center",
    },
    { field: "empName", headerName: "Employee Name", width: 200 },
    { field: "monthStart", headerName: "Month Start", width: 150 },
    // { field: "monthEnd", headerName: "Month End", width: 150 },
    { field: "totWorkDays", headerName: "Total Working Days", width: 150 },
    { field: "presentDays", headerName: "Present Days", width: 150 },
    // { field: "absentDays", headerName: "Absent Days", width: 150 },
    // { field: "payroll", headerName: "Payroll", width: 150 },
    // { field: "profTax", headerName: "Professional Tax", width: 150 },
    // { field: "tds", headerName: "TDS", width: 150 },
    // { field: "currency", headerName: "Currency", width: 150 },
    { field: "totalSalary", headerName: "Total Salary", width: 150 },
    { field: "netPay", headerName: "Net Salary", width: 150 },
    { field: "comments", headerName: "Comments", width: 250 },
    { field: "pl", headerName: "Paid Leaves", width: 100 },
    { field: "sl", headerName: "Sick Leaves", width: 100 },
    {
      field: "options",
      headerName: "Options",
      width: 150,
      renderCell: (params) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => onEditRow(params.row)}
            >
              <IconEdit />
            </IconButton>
          </Tooltip>
          <Tooltip title={LABELS.PRINT_PAYSLIP} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => onPrintPaySlip(params.row)}
            >
              <IconPrint />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const manageRecord = () => {
    const empId = empRef.current.selectedValue.id;
    const monthStart = (
      document.getElementById("monthStart") as HTMLInputElement
    ).value.trim();
    const monthEnd = (
      document.getElementById("monthEnd") as HTMLInputElement
    ).value.trim();
    const totalSalary = (
      document.getElementById("totalSalary") as HTMLInputElement
    ).value.trim();
    const currency = (
      document.getElementById("currency") as HTMLInputElement
    ).value.trim();
    const profTax = (
      document.getElementById("profTax") as HTMLInputElement
    ).value.trim();
    const tds = (
      document.getElementById("tds") as HTMLInputElement
    ).value.trim();
    const netPay = (
      document.getElementById("netPay") as HTMLInputElement
    ).value.trim();
    const payroll = (
      document.getElementById("payroll") as HTMLInputElement
    ).value.trim();
    const totWorkDays = (
      document.getElementById("totWorkDays") as HTMLInputElement
    ).value.trim();
    const absentDays = (
      document.getElementById("absentDays") as HTMLInputElement
    ).value.trim();
    const presentDays = (
      document.getElementById("presentDays") as HTMLInputElement
    ).value.trim();
    const comments = (
      document.getElementById("comments") as HTMLInputElement
    ).value.trim();
    const pl = Number(
      (document.getElementById("pl") as HTMLInputElement).value
    );
    const sl = Number(
      (document.getElementById("sl") as HTMLInputElement).value
    );

    // Validations
    if (!empId) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, "Please Select Employee..!");
      return;
    }
    const args = {
      id: selectedRow.id || -1,
      empId,
      monthStart,
      monthEnd,
      totalSalary,
      currency,
      profTax,
      tds,
      netPay,
      payroll,
      totWorkDays,
      absentDays,
      presentDays,
      comments,
      pl,
      sl,
    };
    API_HANDLER_HOME.managePayslip(args).then((res) => {
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
        setSelectedRow({});
        setManageDialog(false);
        reloadGrid();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, "Error: " + res.error);
      }
    });
  };

  const saveCV = () => {
    // Validations
    if (!profession) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, "Please Enter Profession..!");
      return;
    }

    const args = {
      id: selectedCvRow.id || -1,
      empId: selectedCvRow.empId,
      profession,
      qualification,
      course,
      skill: keySkills,
      workedCountries: countries,
      experience,
      selectedHistory: selectedProfHistory,
      history: recentProfHistory,
    };
    API_HANDLER_MASTERS.manageCv(args).then((res) => {
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
        setSelectedCvRow({});
        setCvDialog(false);
        reloadGrid();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, "Error: " + res.error);
      }
    });
  };

  const deleteUser = ({ id }) => {
    // console.log("deleteUser: ", id);
    setRows(null);
    // getUsers();
  };

  const reloadGrid = () => {
    setRows(null);
    setTimeout(() => {
      getRows();
    }, 2000);
  };

  const onSearch = (e: any) => {
    const qry = e.target.value.trim().toUpperCase();
    clearTimeout(_timer);
    _timer = setTimeout(() => {
      let flist = rows.filter((r: any) => {
        return r.empName.toUpperCase().indexOf(qry) > -1;
      });
      if (flist.length === 0) {
        flist = null;
      } else {
        flist = flist.map((f: any, i: number) => {
          f.slNo = i + 1;
          return f;
        });
      }
      setFilteredRows(flist);
    }, 150);
  };

  const uploadImg = (e: any, f: any) => {
    const file: any = e.target.files[0];
    if (file.size / 1024 / 1024 > 2) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "File size should not exceed more than 2MB..!"
      );
      return;
    }
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let base64: any = reader.result;
      (document.getElementById("profilePic") as HTMLInputElement).setAttribute(
        "src",
        base64
      );
    };
    reader.onerror = (error) => {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, "File upload failed..!");
      // console.log("uploadFile: Error:", error);
    };
  };

  const onChangeDate = (e: any) => {
    const empId = empRef.current.selectedValue.id;
    const fdt = (
      document.getElementById("monthStart") as HTMLInputElement
    ).value.trim();
    const tdt = e.target.value;
    const args = {
      empId,
      fdt,
      tdt,
    };
    if (!empId) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.EMP_IS_EMPTY);
      return;
    }
    if (!fdt) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Please select month start date..!"
      );
      return;
    }
    API_HANDLER_HOME.getPaySlipInfo(args).then((res: any) => {
      console.log("getPaySlipInfo: ", res);
      if (res.status === 200) {
        (document.getElementById("totWorkDays") as HTMLInputElement).value =
          res.response.wd;
        (document.getElementById("absentDays") as HTMLInputElement).value =
          res.response.ad;
        (document.getElementById("presentDays") as HTMLInputElement).value =
          res.response.pd;
      }
    });
  };

  const empRef: any = useRef(null);

  console.log("payslip: ", selectedRow, payslipInfo);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li className="active">{LABELS.PAY_SLIP}</li>
        </ul>
        <h4>{LABELS.PAY_SLIP}</h4>
      </div>

      {rows && (
        <div className="gridOption">
          <TxtBox placeholder={LABELS.SEARCH} onKeyPress={onSearch} />
          <IconButton className="iconBtn" onClick={reloadGrid}>
            <IconReload />
          </IconButton>
          <IconButton
            className="iconBtn"
            onClick={() => {
              setManageDialog(!manageDialog);
              setSelectedRow({});
            }}
          >
            <IconAdd />
          </IconButton>
        </div>
      )}

      <div
        className="grid"
        style={{
          width: "98%",
          height: "80%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {rows ? (
          <DataGrid
            rows={
              !filteredRows ? [] : filteredRows.length > 0 ? filteredRows : rows
            }
            columns={columns}
            rowHeight={50}
            // pageSize={5}
            // rowsPerPageOptions={[5]}
            disableColumnMenu={true}
            disableSelectionOnClick={true}
          />
        ) : (
          <Loader />
        )}
      </div>

      {manageDialog && (
        <UiDialog
          title={
            selectedRow.id
              ? "Edit Payslip: " + selectedRow.empName
              : "Add New Payslip"
          }
          open={true}
          size={"sm"}
          onClose={() => {
            setManageDialog(false);
          }}
        >
          <div className="formGroup">
            <label>{LABELS.EMPLOYEE}</label>
            <AutoComplete
              id={"empList"}
              list={empList}
              childRef={empRef}
              defaultValue={
                selectedRow.empId
                  ? {
                      id: selectedRow.empId,
                      label: selectedRow.empName,
                    }
                  : null
              }
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.MONTH_START_DATE}</label>
            <TxtBox
              id={"monthStart"}
              placeholder={LABELS.MONTH_START_DATE}
              defaultValue={selectedRow.monthStart}
              type={"date"}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.MONTH_END_DATE}</label>
            <TxtBox
              id={"monthEnd"}
              placeholder={LABELS.MONTH_END_DATE}
              defaultValue={selectedRow.monthEnd}
              type={"date"}
              onChange={onChangeDate}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.TOTAL_SALARY}</label>
            <TxtBox
              id={"totalSalary"}
              placeholder={LABELS.TOTAL_SALARY}
              defaultValue={selectedRow.totalSalary}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.CURRENCY}</label>
            <TxtBox
              id={"currency"}
              placeholder={LABELS.CURRENCY}
              defaultValue={selectedRow.currency}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.PROFESSIONAL_TAX}</label>
            <TxtBox
              id={"profTax"}
              placeholder={LABELS.PROFESSIONAL_TAX}
              defaultValue={selectedRow.profTax}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.TDS}</label>
            <TxtBox
              id={"tds"}
              placeholder={LABELS.TDS}
              defaultValue={selectedRow.tds}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.NET_PAY}</label>
            <TxtBox
              id={"netPay"}
              placeholder={LABELS.NET_PAY}
              defaultValue={selectedRow.netPay}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.PAY_ROLL}</label>
            <TxtBox
              id={"payroll"}
              placeholder={LABELS.PAY_ROLL}
              defaultValue={selectedRow.payroll}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.TOTAL_WORKING_DAYS}</label>
            <TxtBox
              id={"totWorkDays"}
              placeholder={LABELS.TOTAL_WORKING_DAYS}
              defaultValue={selectedRow.totWorkDays || payslipInfo.wd}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.ABSENT_DAYS}</label>
            <TxtBox
              id={"absentDays"}
              placeholder={LABELS.ABSENT_DAYS}
              defaultValue={selectedRow.absentDays}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.PRESENT_DAYS}</label>
            <TxtBox
              id={"presentDays"}
              placeholder={LABELS.PRESENT_DAYS}
              defaultValue={selectedRow.presentDays}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.COMMENTS}</label>
            <TxtBox
              id={"comments"}
              placeholder={LABELS.COMMENTS}
              defaultValue={selectedRow.comments}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.PL}</label>
            <TxtBox
              id={"pl"}
              placeholder={LABELS.PL}
              defaultValue={selectedRow.pl}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.SL}</label>
            <TxtBox
              id={"sl"}
              placeholder={LABELS.SL}
              defaultValue={selectedRow.sl}
            />
          </div>
          <div
            className="formGroup"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 25,
            }}
          >
            <div
              style={{
                width: "45%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Btn text={LABELS.SAVE} onClick={manageRecord} />
              <Btn
                text={LABELS.CANCEL}
                bgColor={COLORS.DIM_DARK_GRAY}
                onClick={() => setManageDialog(false)}
              />
            </div>
          </div>
        </UiDialog>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(PaySlip);
