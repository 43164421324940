import React, { useEffect, useReducer, useState } from "react";
import { withSnackbar } from "notistack";

import SkeletonBox from "@components/Custom/SkeletonBox";

const HomeSkeleton = (props: any) => {
  return (
    <div style={{ display: "flex", justifyContent: "space-between", flex: 1 }}>
      <div style={{ width: "5%" }}>
        <SkeletonBox height={"99%"} />
      </div>
      <div style={{ width: "94%" }}>
        <SkeletonBox height={"99%"} />
      </div>
    </div>
  );
};

export default withSnackbar(HomeSkeleton);
