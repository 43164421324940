import { IApiResponse } from "@common/models";
import { API_CONFIG } from "@common/config";
import { COMMON_JS } from "@common/scripts";

import { IArgsPropHome, IArgsFileUpload } from "./models";

interface IHome {
  getResourcePlanning: (args: any) => Promise<IApiResponse>;
  getTasks: (args: any) => Promise<IApiResponse>;
  getResponsibilities: (args: any) => Promise<IApiResponse>;
  getSpares: (args: any) => Promise<IApiResponse>;
  getProjectTypes: (args: any) => Promise<IApiResponse>;

  getUsers: (args: IArgsPropHome) => Promise<IApiResponse>;
  getOrganizations: (args: IArgsPropHome) => Promise<IApiResponse>;
  fileUpload: (args: IArgsFileUpload) => Promise<IApiResponse>;
  getChartData: (args: any) => Promise<IApiResponse>;

  manageLeavePlanner: (args: any) => Promise<IApiResponse>;
  getLeavePlanner: (args: any) => Promise<IApiResponse>;

  getPaySlips: (args: any) => Promise<IApiResponse>;
  managePayslip: (args: any) => Promise<IApiResponse>;

  saveInvoice: (args: any) => Promise<IApiResponse>;
}

export class ApiHome implements IHome {
  getResponsibility = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/responsibility",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  getPaySlipInfo = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/payslipinfo",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  getInvoiceNumber = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/invoicenumber",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  saveApproveTimeSheet = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/approvetimesheet",
        API_CONFIG.METHODS.POST,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  getInvoices = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/invoices",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  saveInvoice = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/saveinvoice",
        API_CONFIG.METHODS.POST,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  getInvoiceTasks = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/invoicetasks",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  getPaySlips = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/payslips",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };
  managePayslip = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/managepayslip",
        API_CONFIG.METHODS.POST,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  getLeavePlanner = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/getleaveplanner",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };
  manageLeavePlanner = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/manageleaveplanner",
        API_CONFIG.METHODS.POST,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  saveTimeSheet = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/savetimesheet",
        API_CONFIG.METHODS.POST,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  getTimeSheet = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/timesheet",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  saveResourcePlanning = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/saveresourceplanning",
        API_CONFIG.METHODS.POST,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  getEmpDrodown = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/empdropdown",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  getProjectTypes = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/projecttypes",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  getSpares = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/spares",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  getResponsibilities = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/responsibilities",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  getTasks = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/tasks",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  getResourcePlanning = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/project/resource-planning",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  getProjectLastUpdateInfo = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/project/lastupdateinfo",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  getProjectFiles = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/project/files",
        API_CONFIG.METHODS.GET,
        args,
        true
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  getApprovePendings = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/project/approvependings",
        API_CONFIG.METHODS.GET,
        args,
        true
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  approveFile = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/project/approve",
        API_CONFIG.METHODS.POST,
        args,
        true
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  getProjectFile = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/account/projectfile",
        API_CONFIG.METHODS.GET,
        args,
        true
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  getUserTypes = async (args: IArgsPropHome): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/usertypes",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  getChartData = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/chart",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  fileUpload = async (args: IArgsFileUpload): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/upload",
        API_CONFIG.METHODS.POST,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  getMainTasks = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/project/maintasks",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  getOrganizations = async (args: IArgsPropHome): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/org-list",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  getUsers = async (args: IArgsPropHome): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/users",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  getFile = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/file",
        API_CONFIG.METHODS.GET,
        args,
        true
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  manageOrganization = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/org",
        API_CONFIG.METHODS.POST,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  manageProject = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/project",
        API_CONFIG.METHODS.POST,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  manageUser = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/user",
        API_CONFIG.METHODS.POST,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  getChartInfo = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/chartinfo",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  editChart = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/edit-chart",
        API_CONFIG.METHODS.POST,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  getCriticalPaths = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/criticalpaths",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };
}
