import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";
import DataGrid, {
  Column,
  Editing,
  Paging,
  Scrolling,
  GroupPanel,
  SearchPanel,
  Grouping,
  Button,
  Lookup,
} from "devextreme-react/data-grid";
import Guid from "devextreme/core/guid";

import Loader from "@components/Custom/Loader";
import AutoComplete from "@components/Custom/AutoComplete";
import TxtBox from "@components/Custom/TxtBox";
import Btn from "@components/Custom/Btn";

import "./styles/rplanning.css";

import { FORMATS, LABELS, NOTIFY, RESOURCE_SLOTS } from "@common/config";
import { ApiHome } from "@apihandlers/home";
import { ApiMasters } from "@apihandlers/masters";
import { DateFunctions } from "@common/datefunctions";
import { COMMON_JS } from "@common/scripts";

const API_HANDLER_HOME = new ApiHome();
const API_HANDLER_MASTER = new ApiMasters();

const TimeSheetBooking = (props: any) => {
  const [comments, setComments] = useState("");
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState<any>(null);
  const [newRowPosition, setNewRowPosition] = React.useState("viewportTop");
  const [changes, setChanges] = React.useState([]);
  const [editRowKey, setEditRowKey] = React.useState(null);
  const [projects, setProjects] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [workPackages, setWorkPackages] = useState([]);
  const [responsibilities, setResponsibilities] = useState([]);
  const [spares, setSpares] = useState([]);
  const [projectTypes, setProjectTypes] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [selectedEmp, setSelectedEmp] = useState({
    id: props.userInfo.empId,
    label: props.userInfo.empName,
  });
  const [selectedStatus, setSelectedStatus] = useState({
    id: "draft",
    label: "Draft",
  });
  const [statusList, setStatusList] = useState([
    {
      id: "draft",
      label: "Draft",
    },
    {
      id: "approve",
      label: "Approve",
    },
  ]);
  const [dayCols, setDayCols] = useState([]);

  useEffect(() => {
    if (loading) {
      getProjects();
    }
  }, [loading]);

  const getProjects = () => {
    const args = { projectStatus: "Active" };
    API_HANDLER_MASTER.getProjectMaster(args)
      .then((res: any) => {
        // console.log("getProjects: res:", res);
        const { status, response } = res;
        if (status === 200) {
          const list = response.records.map((r: any) => {
            return {
              id: r.id,
              label: r.projectName,
            };
          });
          getTasks(list);
        }
      })
      .catch((err) => {
        // console.log("getProjects: ", err);
      });
  };

  const getTasks = (projectList = []) => {
    const args: any = {
      activeStatus: "true",
    };
    API_HANDLER_HOME.getTasks(args).then((res: any) => {
      const { status, response } = res;
      if (status === 200) {
        const list = response.records.map((t) => {
          return {
            id: t.id,
            name: t.taskTypeName,
          };
        });
        getResponsibilities(projectList, list);
      }
    });
  };

  const getResponsibilities = (projectList = [], tasksList = []) => {
    const args: any = {};
    API_HANDLER_HOME.getResponsibilities(args).then((res: any) => {
      const { status, response } = res;
      if (status === 200) {
        getSpares(projectList, tasksList, response.records);
      }
    });
  };

  const getSpares = (projectList = [], tasksList = [], resList = []) => {
    const args: any = {};
    API_HANDLER_HOME.getSpares(args).then((res: any) => {
      const { status, response } = res;
      if (status === 200) {
        getProjectTypes(projectList, tasksList, resList, response.records);
      }
    });
  };

  const getProjectTypes = (
    projectList = [],
    tasksList = [],
    resList = [],
    sparesList = []
  ) => {
    const args: any = {};
    API_HANDLER_HOME.getProjectTypes(args).then((res: any) => {
      const { status, response } = res;
      if (status === 200) {
        const list = response.records.map((r: any) => {
          return {
            id: r.id,
            name: r.projectTypeName,
          };
        });
        getWorkPackages(projectList, tasksList, resList, sparesList, list);
      }
    });
  };

  const getWorkPackages = (
    projectList = [],
    tasksList = [],
    resList = [],
    sparesList = [],
    projectTypes = []
  ) => {
    const args: any = {};
    API_HANDLER_MASTER.getWorkPackages(args).then((res: any) => {
      const { status, response } = res;
      if (status === 200) {
        const list = response.records.map((r: any) => {
          return {
            id: r.workPackage,
            name: r.workPackage,
          };
        });
        getEmpDrodown(
          projectList,
          tasksList,
          resList,
          sparesList,
          projectTypes,
          list
        );
      }
    });
  };

  const getEmpDrodown = (
    projectList = [],
    tasksList = [],
    resList = [],
    sparesList = [],
    projectTypes = [],
    workPackages = []
  ) => {
    const args: any = {};
    API_HANDLER_HOME.getEmpDrodown(args).then((res: any) => {
      const { status, response } = res;
      if (status === 200) {
        setProjects(projectList);
        setTasks(tasksList);
        setResponsibilities(resList);
        setSpares(sparesList);
        setProjectTypes(projectTypes);
        setWorkPackages(workPackages);
        const list = response.records.map((r: any) => {
          return {
            id: r.id,
            label: r.name,
          };
        });
        setEmployees(list);
        setLoading(false);
      }
    });
  };

  const onAddButtonClick = React.useCallback((e: any) => {
    const key = new Guid().toString();
    setChanges([
      {
        key,
        type: "insert",
        insertAfterKey: e.row.key,
      },
    ]);
    setEditRowKey(key);
  }, []);

  const isAddButtonVisible = React.useCallback(({ row }) => !row.isEditing, []);

  const getTimeSheet = () => {
    const fromDt = (document.getElementById("fromDt") as HTMLInputElement)
      .value;
    setRows(null);
    // Check for Saturday => fromDt
    let day = DateFunctions.getFormatedDate(
      fromDt,
      FORMATS.SQL_DATE_ONLY,
      "dddd"
    );
    if (day != "Saturday") {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, "Please select saturday..!");
      return;
    }
    // Generate Day Cols
    let list = [];
    let dtTimeStamp = new Date(fromDt).getTime();
    let endDt = new Date(fromDt).getTime() + 86400000 * 7;
    while (dtTimeStamp < endDt) {
      list.push(DateFunctions.getDateFromSeconds(dtTimeStamp, "DD-MM-YYYY"));
      dtTimeStamp += 86400 * 1000;
    }
    setDayCols(list);
    // console.log("fromDt: ", new Date(fromDt), new Date(fromDt).getTime());
    const args = {
      empId: empRef.current.selectedValue.id,
      fromDt,
      status: statusRef.current.selectedValue.id,
    };
    API_HANDLER_HOME.getTimeSheet(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setRows(response.records);
        setComments(response.comments);
        if (args.status !== "draft") {
          setHasChanges(true);
        }
      }
    });
  };

  const saveRecords = () => {
    if (rows.length === 0) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, "No Data Found..!");
      return;
    }
    const fromDt = (document.getElementById("fromDt") as HTMLInputElement)
      .value;
    const normalHrs = (document.getElementById("normalHrs") as HTMLInputElement)
      .value;
    const args = {
      rows,
      empId: empRef.current.selectedValue.id,
      fromDt,
      status: statusRef.current.selectedValue.id,
      normalHrs,
    };
    setRows(null);
    API_HANDLER_HOME.saveTimeSheet(args).then((res) => {
      if (res.status === 200) {
        COMMON_JS.showNotify(
          props,
          NOTIFY.SUCCESS,
          "Records Saved Successfully..!"
        );
        getTimeSheet();
        setHasChanges(false);
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, "Error: " + res.error);
        getTimeSheet();
        setHasChanges(false);
      }
    });
  };

  const onRowInserted = React.useCallback((e: any) => {
    // console.log("onRowInserted: ", e);
    e.component.navigateToRow(e.key);
  }, []);

  const onSaving = React.useCallback(
    (e: any) => {
      // console.log("onSaving: ", e, changes, rows);
      e.cancel = true;
      if (!changes[0] || !changes[0].data) {
        COMMON_JS.showNotify(
          props,
          NOTIFY.WARNING,
          "Please fill the mandatory fields to continue..!"
        );
        return;
      } else {
        let rowId = changes[0].key;
        let saveType = changes[0].type;
        let chng = changes;
        if (saveType === "update") {
          let changesRow = rows.filter((r: any) => {
            return r.ID === rowId;
          });
          // console.log("changesRow: ", changesRow, chng);
          if (changesRow.length > 0) {
            for (let key in changesRow[0]) {
              if (key !== "ID") {
                if (!(key in chng[0]["data"])) {
                  chng[0]["data"][key] = changesRow[0][key];
                }
              }
            }
          }
        }
        const { projectId, taskId } = chng[0].data;
        if (!taskId) {
          COMMON_JS.showNotify(props, NOTIFY.WARNING, "Please Select Task..!");
          return;
        }
        if (!projectId) {
          COMMON_JS.showNotify(
            props,
            NOTIFY.WARNING,
            "Please Select Project..!"
          );
          return;
        }
        let tmpRows = rows;
        let data = changes[0].data;
        if (rowId.indexOf("ID-") === -1) {
          // New Record
          let parentRowId = changes[0].insertAfterKey;
          if (!parentRowId) {
            parentRowId = changes[0].key;
          }
          // console.log("New Record: ", rowId, parentRowId, rows);
          // ReGenerate Rows
          let tRows = [];
          for (let indx in rows) {
            tRows.push(rows[indx]);
            if (tRows[indx]["ID"] === parentRowId) {
              if (saveType === "update") {
                for (let key in data) {
                  tRows[indx][key] = data[key];
                }
              } else {
                let empArr = employees.filter((e) => {
                  return e.name === tRows[indx]["resource"];
                });
                let row = {
                  ID: rowId,
                  resource: empArr.length > 0 ? empArr[0].name : "",
                };
                for (let key in data) {
                  row[key] = data[key];
                }
                tRows.push(row);
              }
            }
          }
          setRows(tRows);
        } else {
          // Existing Record Update
          for (let indx in rows) {
            if (tmpRows[indx]["ID"] === rowId) {
              for (let key in data) {
                tmpRows[indx][key] = data[key];
              }
              break;
            }
          }
          setRows(tmpRows);
        }
        // console.log("final: ", rows, tmpRows, rowId);
        setChanges([]);
        setEditRowKey(null);
        setHasChanges(true);
        // e.promise = saveChange(dispatch, e.changes[0]);
      }
    },
    [changes, rows]
  );

  // const onSaving = (e: any) => {
  //   // console.log("onSaving: ", changes, e);
  //   if (!changes[0] || !changes[0].data) {
  //     COMMON_JS.showNotify(
  //       props,
  //       NOTIFY.WARNING,
  //       "Please Allot the slots to save the record..!"
  //     );
  //   }
  //   e.cancel = true;
  //   // setChanges([]);
  //   // setEditRowKey(null);
  //   // e.promise = saveChange(dispatch, e.changes[0]);
  // };

  const empRef: any = useRef(null);
  const gridRef: any = useRef(null);
  const statusRef: any = useRef(null);

  // console.log("timesheet: ", rows, changes);

  let title = LABELS.TIME_SHEET;

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li className="active">{title}</li>
        </ul>
        <h4>{title}</h4>
      </div>

      <div
        id="timeSheet"
        style={{
          marginTop: 15,
          overflowX: "auto",
          width: "98%",
          overflow: "hidden",
        }}
      >
        {/* {!loading ? (
          <div
            style={{
              height: 300,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader />
          </div>
        ) : ( */}
        <React.Fragment>
          <div
            style={{ width: "100%", display: "flex", flexDirection: "column" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                className="formGroup"
                style={{
                  width: "20%",
                  marginRight: 15,
                }}
              >
                <label>{LABELS.EMPLOYEE}</label>
                <AutoComplete
                  id={"emplist"}
                  list={employees}
                  childRef={empRef}
                  defaultValue={{
                    id: props.userInfo.empId,
                    label: props.userInfo.empName,
                  }}
                  disabled={true}
                />
              </div>
              <div
                className="formGroup"
                style={{
                  width: "20%",
                  marginRight: 15,
                }}
              >
                <label>{LABELS.DATE}</label>
                <TxtBox
                  type={"date"}
                  id="fromDt"
                  defaultValue={DateFunctions.getDate(
                    "today",
                    "from",
                    "YYYY-MM-DD"
                  )}
                />
              </div>
              <div
                className="formGroup"
                style={{
                  width: "20%",
                }}
              >
                <label>{LABELS.STATUS}</label>
                <AutoComplete
                  id={"statuslist"}
                  list={statusList}
                  childRef={statusRef}
                  defaultValue={selectedStatus}
                  disabled={true}
                />
              </div>
            </div>
            <div className="formGroup">
              <Btn
                text={LABELS.LOAD}
                onClick={getTimeSheet}
                loading={loading}
              />
            </div>
            <div
              className="formGroup"
              style={{
                width: "100%",
                flexDirection: "row",
                display: "flex",
              }}
            >
              <div
                className="formGroup"
                style={{ width: "10%", marginRight: 20 }}
              >
                <label>{LABELS.NORMAL_HRS}</label>
                <TxtBox
                  placeholder={LABELS.NORMAL_HRS}
                  id={"normalHrs"}
                  defaultValue={9 * 5}
                  disabled={true}
                />
              </div>
              {rows && (
                <div
                  className="formGroup"
                  style={{
                    width: "30%",
                  }}
                >
                  <label>{LABELS.ADMIN_COMMENTS}</label>
                  <TxtBox
                    placeholder={LABELS.ADMIN_COMMENTS}
                    id={"adminComments"}
                    disabled={true}
                    defaultValue={comments}
                  />
                </div>
              )}
            </div>
          </div>
          {rows && (
            <div
              style={{
                flexDirection: "column",
              }}
            >
              <DataGrid
                id="gridContainer"
                ref={gridRef}
                dataSource={rows}
                keyExpr="ID"
                showBorders={true}
                allowColumnResizing={true}
                columnMinWidth={150}
                columnAutoWidth={true}
                onRowInserted={onRowInserted}
                onSaving={onSaving}
              >
                <Scrolling columnRenderingMode="virtual" />
                <Paging enabled={true} />
                <Editing
                  mode="row"
                  allowAdding={true}
                  allowDeleting={true}
                  allowUpdating={true}
                  confirmDelete={true}
                  useIcons={true}
                  newRowPosition={newRowPosition}
                  changes={changes}
                  onChangesChange={setChanges}
                  editRowKey={editRowKey}
                  onEditRowKeyChange={setEditRowKey}
                />
                <Column dataField="projectId" caption="Project">
                  <Lookup
                    dataSource={projects}
                    displayExpr="label"
                    valueExpr="id"
                  />
                </Column>
                <Column dataField="taskId" caption="Task" width={300}>
                  <Lookup
                    dataSource={tasks}
                    displayExpr="name"
                    valueExpr="id"
                  />
                </Column>
                <Column dataField="comments" caption="Description" width={500}>
                  <Lookup
                    dataSource={workPackages}
                    displayExpr="name"
                    valueExpr="id"
                  />
                </Column>
                {/* <Column
                  dataField="approveComments"
                  caption="Admin Description"
                  allowEditing={true}
                /> */}
                {/* <Column
                  dataField="timeCode"
                  caption="Timecode"
                  width={100}
                  minWidth={100}
                />
                <Column
                  dataField="timeUnit"
                  caption="Time Unit"
                  width={100}
                  minWidth={100}
                /> */}
                {dayCols.map((colName, i) => {
                  return (
                    <Column
                      dataField={"day" + (i + 1)}
                      caption={colName}
                      minWidth={50}
                    />
                  );
                })}
                <Column type="buttons" caption="Options" minWidth={100}>
                  <Button
                    icon="add"
                    onClick={onAddButtonClick}
                    visible={isAddButtonVisible}
                  />
                  <Button name="edit" />
                  <Button name="save" />
                  <Button name="cancel" />
                </Column>
                <GroupPanel visible={true} />
                <SearchPanel visible={true} />
                <Grouping autoExpandAll={true} />
              </DataGrid>

              <div
                className="formGroup"
                style={{
                  marginTop: 15,
                }}
              >
                {hasChanges && (
                  <Btn
                    text={LABELS.SAVE}
                    onClick={saveRecords}
                    loading={loading}
                  />
                )}
              </div>
            </div>
          )}
        </React.Fragment>
      </div>
    </React.Fragment>
  );
};

export default withSnackbar(TimeSheetBooking);
