import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";
import HtmlEditor, {
  TableContextMenu,
  TableResizing,
  Toolbar,
  Item,
} from "devextreme-react/html-editor";
import { GetApp as IconExport } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";

import Btn from "@components/Custom/Btn";
import AutoComplete from "@components/Custom/AutoComplete";
import ConfirmDialog from "@components/Custom/ConfirmDialog";
import Loader from "@components/Custom/Loader";

import { CONFIRMS, DEFAULTS, LABELS, NOTIFY, WARNINGS } from "@common/config";
import { ApiHome } from "@apihandlers/home";
import { ApiMasters } from "@apihandlers/masters";
import { COMMON_JS } from "@common/scripts";

const API_HANDLER_HOME = new ApiHome();
const API_HANDLER_MASTERS = new ApiMasters();

const MinutesOfMeeting = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [history, setHistory] = useState<any>(null);
  const [apiArgs, setApiArgs] = useState<any>({});
  const [markup, setMarkup] = useState("");
  const [historyId, setHistoryId] = useState("");
  const [htmlLoading, setHtmlLoading] = useState(false);
  const jRef = useRef(null);

  const options = {
    data: [[]],
    minDimensions: [10, 10],
  };

  useEffect(() => {
    if (projects.length === 0) {
      getProjects();
    }
  }, [projects]);

  const getProjects = () => {
    const args = { dropdown: true, projectStatus: "Active" };
    API_HANDLER_MASTERS.getProjectMaster(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setProjects(
          response.records.map((p: any) => {
            return {
              label: p.projectName,
              id: p.id,
            };
          })
        );
        setLoading(false);
      }
    });
  };

  const onConfirmApprove = () => {
    setLoading(true);
    API_HANDLER_MASTERS.saveMinutesOfMeeting(apiArgs).then((res) => {
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, "Saved Successfully..!");
        setLoading(false);
        setApiArgs({});
        getData();
      } else {
        setLoading(false);
        COMMON_JS.showNotify(props, NOTIFY.ERROR, "Not Saved Successfully..!");
      }
    });
  };

  const getData = () => {
    const projectId = projectRef.current.selectedValue.id;
    if (!projectId) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.PROJECT_EMPTY);
      return;
    }
    const args = {
      projectId,
    };
    setLoading(true);
    API_HANDLER_MASTERS.getMinutesOfMeeting(args).then((res: any) => {
      if (res.status === 200) {
        let recs = res.response;
        if (recs.length > 0) {
          setMarkup(recs[0].html_text);
          setHistoryId(recs[0].id);
        } else {
          setMarkup("");
          setHistoryId("");
        }
        setHistory(recs);
        setLoading(false);
      }
    });
  };

  const saveData = () => {
    const html = document.querySelector(".ql-editor").innerHTML;
    const projectId = projectRef.current.selectedValue.id;
    if (!projectId) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.PROJECT_EMPTY);
      return;
    }
    if (html === "") {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, "Please enter data..!");
      return;
    }
    const args = {
      projectId,
      html,
    };
    setApiArgs(args);
    setMarkup(html);
  };

  const exportData = () => {
    let projectName = "";
    if (projectRef.current) {
      projectName = projectRef.current.selectedValue.label;
    }
    const html = document.querySelector(".ql-editor").innerHTML;
    const title = LABELS.MINUTES_OF_MEETING + " - " + projectName;
    let header = "<html>";
    header +=
      "<head><title>" +
      title +
      "</title><style>@page{margin-top:10px;size: A3 landscape}@media print {td,th{border:1px solid #000;border-bottom: none;} tr:last-child td {border-bottom: 1px solid #000;} td:not(:last-child),th:not(:last-child){border-right: none;}}</style></head>";
    let body =
      "<body style='border:1px solid #ccc;'><div style='width:95%;margin:auto;display:flex;flex-direction:column;justify-content:center;'><h2 style='text-align: center;margin-top:20px;margin-bottom:10px;'>" +
      title +
      "</h2>";
    let htmlText = header + body + html + "</div></body></html>";
    console.log(htmlText);
    const printWin = window.open("", "", "toolbar=0,scrollbars=0,status=0");
    printWin.document.write(htmlText);
    printWin.document.close();
    printWin.focus();
    printWin.print();
    printWin.close();
  };

  const projectRef: any = useRef();
  const htmlEditor = useRef(null);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li className="active">{LABELS.MINUTES_OF_MEETING}</li>
        </ul>
        <h4>{LABELS.MINUTES_OF_MEETING}</h4>
      </div>

      <div id="fileUploadForm" style={{ marginTop: 15 }}>
        <div className="formGroup" style={{ width: "30%" }}>
          <label>{LABELS.PROJECT_NAME}</label>
          <AutoComplete id={"projects"} list={projects} childRef={projectRef} />
        </div>
        <div
          className="formGroup"
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 0,
            marginBottom: 15,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginRight: 15,
            }}
          >
            <Btn text={LABELS.LOAD} onClick={getData} loading={loading} />
          </div>
          {/* {historyId !== "" && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginRight: 15,
              }}
            >
              <Btn
                text={"Add New"}
                onClick={() => {
                  setHistoryId("");
                  setMarkup("");
                  setHtmlLoading(true);
                  setTimeout(() => {
                    setHtmlLoading(false);
                  }, 500);
                }}
                loading={loading}
              />
            </div>
          )} */}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Tooltip title={LABELS.EXPORT_TO_PDF}>
              <IconButton
                disabled={loading || markup === ""}
                onClick={exportData}
              >
                <IconExport />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>

      {loading ? (
        <div className="flexCenter">
          <Loader />
        </div>
      ) : (
        <div
          id={"gridBox"}
          style={{
            width: "98%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "78%" }}>
            {htmlLoading ? (
              <div
                className="flexCenter"
                style={{
                  minHeight: 200,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Loader />
              </div>
            ) : (
              <HtmlEditor
                id="gridEditor"
                ref={htmlEditor}
                defaultValue={markup}
              >
                <TableContextMenu enabled={true} />
                <TableResizing enabled={true} />
                <Toolbar>
                  <Item name="undo" />
                  <Item name="redo" />
                  <Item name="bold" />
                  <Item name="italic" />
                  <Item name="strike" />
                  <Item name="underline" />
                  <Item name="color" />
                  <Item name="background" />
                  <Item name="separator" />
                  <Item
                    name="size"
                    acceptedValues={[
                      "8pt",
                      "10pt",
                      "12pt",
                      "14pt",
                      "18pt",
                      "24pt",
                      "36pt",
                    ]}
                  />
                  <Item name="alignLeft" />
                  <Item name="alignCenter" />
                  <Item name="alignRight" />
                  <Item name="alignJustify" />
                  <Item name="separator" />
                  <Item name="orderedList" />
                  <Item name="bulletList" />
                  <Item name="separator" />
                  <Item name="insertTable" />
                  <Item name="insertHeaderRow" />
                  <Item name="insertRowAbove" />
                  <Item name="insertRowBelow" />
                  <Item name="separator" />
                  <Item name="insertColumnLeft" />
                  <Item name="insertColumnRight" />
                  <Item name="separator" />
                  <Item name="deleteColumn" />
                  <Item name="deleteRow" />
                  <Item name="deleteTable" />
                  <Item name="separator" />
                  <Item name="cellProperties" />
                  <Item name="tableProperties" />
                </Toolbar>
              </HtmlEditor>
            )}
            {history && (
              <div
                className="formGroup"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 15,
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Btn
                    text={LABELS.SAVE}
                    onClick={saveData}
                    loading={loading}
                  />
                </div>
              </div>
            )}
          </div>
          <div style={{ width: "20%", height: 500, overflowY: "auto" }}>
            {history &&
              history.map((h: any, i: number) => {
                return (
                  <div
                    key={"h-" + i}
                    className={
                      "historyBox " +
                      (Number(historyId) === Number(h.id) ? "active" : "")
                    }
                    onClick={() => {
                      setHistoryId(h.id);
                      setHtmlLoading(true);
                      setTimeout(() => {
                        setMarkup(h.html_text);
                        setHtmlLoading(false);
                      }, 1000);
                    }}
                  >
                    <h4>{h.logtime}</h4>
                    <p>{h.userName}</p>
                  </div>
                );
              })}
          </div>
        </div>
      )}

      <ConfirmDialog
        open={apiArgs.projectId || false}
        title={LABELS.CONFIRM}
        msg={CONFIRMS.SURE_TO_SAVE}
        size={DEFAULTS.DIALOG_SIZE}
        onClose={() => setApiArgs({})}
        onOk={onConfirmApprove}
      />
    </React.Fragment>
  );
};

export default withSnackbar(MinutesOfMeeting);
