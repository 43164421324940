import React, { useState } from "react";
import { MenuList, MenuItem } from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";

import Btn from "@components/Custom/Btn";

// Reports
import ReportUserLog from "./ReportUserLog";
import ReportResourcePlanning from "./ReportResourcePlanning";
import ReportEmployeeLeaves from "./ReportEmployeeLeaves";
import ReportCost from "./ReportCost";
import ReportInvoice from "./ReportInvoice";
import ReportInvoiceRegister from "./ReportInvoiceRegister";
import ReportTimeSheet from "./ReportTimeSheet";
import ReportProjects from "./ReportProjects";

import { CONFIG, LABELS } from "@common/config";

import "./dashboard.css";
import "./styles/reports.css";
import "./styles/datatables.min.css";
import "devextreme/dist/css/dx.light.css";

const resourcePlanReport = require("@assets/images/reports/resourceplan.png");
const projectsReportImg = require("@assets/images/reports/projectsreport.png");
const timeSheetReport = require("@assets/images/reports/timesheetreport.png");
const invoiceReport = require("@assets/images/reports/invoicereport.png");
const costReport = require("@assets/images/reports/costreport.png");
const employeeLeavesReport = require("@assets/images/reports/employeeleavesreport.png");
const approvalLogReport = require("@assets/images/reports/approvallogreport.png");
const userLogreport = require("@assets/images/reports/userlogreport.png");

const Dashboard = (props: any = {}) => {
  const [dashMenu, setDashMenu] = useState(LABELS.HOME);
  const { childRef, userInfo } = props;
  const setMenu = () => {
    setDashMenu(LABELS.HOME);
  };
  childRef.setMenu = setMenu;

  let menuUserLog = false;
  let projectsReport = false;
  let resourcePlanningReport = false;
  let timesheetReport = false;
  if (userInfo.role.id === CONFIG.USER_ROLES.Admin) {
    menuUserLog = true;
    projectsReport = true;
    resourcePlanningReport = true;
    timesheetReport = true;
  } else if (userInfo.role.id === CONFIG.USER_ROLES.Director) {
    projectsReport = true;
    resourcePlanningReport = true;
    timesheetReport = true;
  } else if (userInfo.role.id === CONFIG.USER_ROLES.PlanningEngineer) {
    timesheetReport = true;
  }

  return (
    <React.Fragment>
      {dashMenu == LABELS.HOME && (
        <div className="userDashboard">
          <div className="pageInfo">
            <ul className="breadcrumbs">
              <li>{LABELS.HOME}</li>
              <li className="dim">{" / "}</li>
              <li className="active">{LABELS.REPORTS}</li>
            </ul>
            <h4>{LABELS.REPORTS}</h4>
          </div>

          <MenuList dense className="dashboardMenuWrap">
            {resourcePlanningReport && (
              <MenuItem
                className="dashboardMenu"
                onClick={() => {
                  setDashMenu(LABELS.RESOURCE_PLANNING_REPORT);
                }}
              >
                <img src={resourcePlanReport} />
                <h4>{LABELS.RESOURCE_PLANNING_REPORT}</h4>
              </MenuItem>
            )}

            {projectsReport && (
              <MenuItem
                className="dashboardMenu"
                onClick={() => {
                  setDashMenu(LABELS.PROJECTS_REPORT);
                }}
              >
                <img src={projectsReportImg} />
                <h4>{LABELS.PROJECTS_REPORT}</h4>
              </MenuItem>
            )}

            {timesheetReport && (
              <MenuItem
                className="dashboardMenu"
                onClick={() => {
                  setDashMenu(LABELS.TIME_SHEET_REPORT);
                }}
              >
                <img src={timeSheetReport} />
                <h4>{LABELS.TIME_SHEET_REPORT}</h4>
              </MenuItem>
            )}

            {menuUserLog && (
              <MenuItem
                className="dashboardMenu"
                onClick={() => {
                  setDashMenu(LABELS.INVOICE_REPORT);
                }}
              >
                <img src={invoiceReport} />
                <h4>{LABELS.INVOICE_REPORT}</h4>
              </MenuItem>
            )}

            {menuUserLog && (
              <MenuItem
                className="dashboardMenu"
                onClick={() => {
                  setDashMenu(LABELS.INVOICE_REGISTER);
                }}
              >
                <img src={invoiceReport} />
                <h4>{LABELS.INVOICE_REGISTER}</h4>
              </MenuItem>
            )}

            {menuUserLog && (
              <MenuItem
                className="dashboardMenu"
                onClick={() => {
                  setDashMenu(LABELS.COST_REPORT);
                }}
              >
                <img src={costReport} />
                <h4>{LABELS.COST_REPORT}</h4>
              </MenuItem>
            )}

            {menuUserLog && (
              <MenuItem
                className="dashboardMenu"
                onClick={() => {
                  setDashMenu(LABELS.EMPLOYEE_LEAVES_REPORT);
                }}
              >
                <img src={employeeLeavesReport} />
                <h4>{LABELS.EMPLOYEE_LEAVES_REPORT}</h4>
              </MenuItem>
            )}
            {/* {menuUserLog && (
              <MenuItem
                className="dashboardMenu"
                onClick={() => {
                  setDashMenu(LABELS.APPROVAL_LOG_REPORT);
                }}
              >
                <img src={approvalLogReport} />
                <h4>{LABELS.APPROVAL_LOG_REPORT}</h4>
              </MenuItem>
            )} */}
            {menuUserLog && (
              <MenuItem
                className="dashboardMenu"
                onClick={() => {
                  setDashMenu(LABELS.USER_LOG_REPORT);
                }}
              >
                <img src={userLogreport} />
                <h4>{LABELS.USER_LOG_REPORT}</h4>
              </MenuItem>
            )}
          </MenuList>
        </div>
      )}

      {dashMenu == LABELS.PROJECTS_REPORT && (
        <ReportProjects setDashMenu={setDashMenu} />
      )}

      {dashMenu == LABELS.TIME_SHEET_REPORT && (
        <ReportTimeSheet setDashMenu={setDashMenu} />
      )}

      {dashMenu == LABELS.INVOICE_REPORT && (
        <ReportInvoice setDashMenu={setDashMenu} />
      )}

      {dashMenu == LABELS.INVOICE_REGISTER && (
        <ReportInvoiceRegister setDashMenu={setDashMenu} />
      )}

      {dashMenu == LABELS.COST_REPORT && (
        <ReportCost setDashMenu={setDashMenu} />
      )}

      {dashMenu == LABELS.EMPLOYEE_LEAVES_REPORT && (
        <ReportEmployeeLeaves setDashMenu={setDashMenu} />
      )}

      {dashMenu == LABELS.USER_LOG_REPORT && (
        <ReportUserLog setDashMenu={setDashMenu} />
      )}

      {dashMenu == LABELS.RESOURCE_PLANNING_REPORT && (
        <ReportResourcePlanning setDashMenu={setDashMenu} />
      )}
    </React.Fragment>
  );
};

export default Dashboard;
