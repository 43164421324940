import React, { useEffect, useRef, useState } from "react";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import {
  Replay as IconReload,
  AddBox as IconAdd,
  Edit as IconEdit,
  Check as IconCheck,
  Close as IconClose,
  PictureAsPdf as IconPdf,
  Article as IconFile,
} from "@mui/icons-material";

import TxtBox from "@components/Custom/TxtBox";
import UiDialog from "@components/Custom/UiDialog";
import RadioBtn from "@components/Custom/RadioBtn";
import Btn from "@components/Custom/Btn";
import SwitchBtn from "@components/Custom/SwitchBtn";
import AutoComplete from "@components/Custom/AutoComplete";
import ConfirmDialog from "@components/Custom/ConfirmDialog";
import Loader from "@components/Custom/Loader";
import UsersSkeleton from "./UsersSkeleton";

import {
  COLORS,
  ERRORS,
  LABELS,
  LEAVE_STATUS,
  NOTIFY,
  SUCCESS,
  WARNINGS,
} from "@common/config";
import { ApiMasters } from "@apihandlers/masters";

import { ApiHome } from "@apihandlers/home";
import { COMMON_JS } from "@common/scripts";

const API_HANDLER_MASTERS = new ApiMasters();
const API_HANDLER_HOME = new ApiHome();

let _timer = null;

const LeavePlanner = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState<any>(null);
  const [empList, setEmpList] = useState([]);

  const [fileId, setFileId] = useState("");
  const [users, setUsers] = useState([]);
  const [orgs, setOrgs] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});

  useEffect(() => {
    if (loading) {
      getEmpList();
    }
  }, [loading]);

  const getEmpList = () => {
    const args = {};
    API_HANDLER_MASTERS.getEmployeeMaster(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.empName,
          };
        });
        setEmpList(list);
        getRows();
      }
    });
  };

  const getRows = () => {
    const args = {};
    API_HANDLER_HOME.getLeavePlanner(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records;
        for (let i = 0; i < response.records.length; i++) {
          list[i]["slNo"] = i + 1;
          if (list[i]["activeStatus"]) {
            list[i]["activeStatusText"] = "Yes";
          } else {
            list[i]["activeStatusText"] = "No";
          }
        }
        setRows(list);
        setFilteredRows([]);
        setLoading(false);
      }
    });
  };

  const onEditRow = (row: any) => {
    setSelectedRow(row);
    setManageDialog(true);
  };

  const columns: GridColDef[] = [
    {
      field: "slNo",
      headerName: "Sl No.",
      width: 100,
      type: "number",
      align: "center",
      headerAlign: "center",
    },
    { field: "empName", headerName: "Employee Name", width: 300 },
    { field: "leaveFrom", headerName: "Leave From", width: 200 },
    { field: "leaveTo", headerName: "Leave To", width: 200 },
    { field: "status", headerName: "Status", width: 200 },
  ];

  const onApprove = (row: any = {}, status = LEAVE_STATUS.APPLIED) => {
    const args = {
      id: row.id,
      empId: row.empId,
      leaveFrom: row.leaveFrom,
      leaveTo: row.leaveTo,
      type: LEAVE_STATUS.APPROVE,
      status,
    };
    API_HANDLER_HOME.manageLeavePlanner(args).then((res) => {
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
        setSelectedRow({});
        setManageDialog(false);
        reloadGrid();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.RECORD_FAILED);
      }
    });
  };

  const manageRecord = () => {
    const empId = empListRef.current.selectedValue.id;
    const leaveFrom = (
      document.getElementById("leaveFrom") as HTMLInputElement
    ).value.trim();
    const leaveTo = (
      document.getElementById("leaveTo") as HTMLInputElement
    ).value.trim();
    // Validations
    if (!empId) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.EMP_IS_EMPTY);
      return;
    }
    if (!leaveFrom) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, "Leave From is mandatory..!");
      return;
    }
    if (!leaveTo) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, "Leave To is mandatory..!");
      return;
    }

    const args = {
      id: selectedRow.id || -1,
      empId,
      leaveFrom,
      leaveTo,
      type: "request",
    };
    API_HANDLER_HOME.manageLeavePlanner(args).then((res) => {
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
        setSelectedRow({});
        setManageDialog(false);
        reloadGrid();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.RECORD_FAILED);
      }
    });
  };

  const deleteUser = ({ id }) => {
    // console.log("deleteUser: ", id);
    setRows(null);
    // getUsers();
  };

  const reloadGrid = () => {
    setRows(null);
    setTimeout(() => {
      getRows();
    }, 2000);
  };

  const onSearch = (e: any) => {
    const qry = e.target.value.trim().toUpperCase();
    clearTimeout(_timer);
    _timer = setTimeout(() => {
      let flist = rows.filter((r: any) => {
        return r.empName.toUpperCase().indexOf(qry) > -1;
      });
      // console.log("flist: ", rows, flist, qry);
      if (flist.length === 0) {
        flist = null;
      } else {
        flist = flist.map((f, i) => {
          f.slNo = i + 1;
          return f;
        });
      }
      setFilteredRows(flist);
    }, 150);
  };

  const empListRef: any = useRef();

  // console.log("LeavePlanner: ", props);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li className="active">{LABELS.LEAVE_PLANNER}</li>
        </ul>
        <h4>{LABELS.LEAVE_PLANNER}</h4>
      </div>

      {rows && (
        <div className="gridOption">
          <TxtBox placeholder={LABELS.SEARCH} onKeyPress={onSearch} />
          <IconButton className="iconBtn" onClick={reloadGrid}>
            <IconReload />
          </IconButton>
          <IconButton
            className="iconBtn"
            onClick={() => {
              setManageDialog(!manageDialog);
              setSelectedRow({});
            }}
          >
            <IconAdd />
          </IconButton>
        </div>
      )}

      <div
        className="grid"
        style={{
          width: "98%",
          height: "80%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {rows ? (
          <DataGrid
            rows={
              !filteredRows ? [] : filteredRows.length > 0 ? filteredRows : rows
            }
            columns={columns}
            rowHeight={50}
            // pageSize={5}
            // rowsPerPageOptions={[5]}
            disableColumnMenu={true}
            disableSelectionOnClick={true}
          />
        ) : (
          <Loader />
        )}
      </div>

      {manageDialog && (
        <UiDialog
          title={
            selectedRow.id
              ? "Edit Leave Planner: " + selectedRow.empName
              : "Add New Leave Planner"
          }
          open={true}
          size={"sm"}
          onClose={() => {
            setManageDialog(false);
          }}
        >
          <div className="formGroup">
            <label>{LABELS.EMPLOYEE}</label>
            <AutoComplete
              id={"empList"}
              list={empList}
              childRef={empListRef}
              defaultValue={{
                id: props.userInfo.empId,
                label: props.userInfo.empName,
              }}
              disabled={true}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.START_DATE}</label>
            <TxtBox
              id={"leaveFrom"}
              placeholder={LABELS.START_DATE}
              defaultValue={selectedRow.leaveFrom}
              type={"date"}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.FINISH_DATE}</label>
            <TxtBox
              id={"leaveTo"}
              placeholder={LABELS.FINISH_DATE}
              defaultValue={selectedRow.leaveTo}
              type={"date"}
            />
          </div>
          <div
            className="formGroup"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 25,
            }}
          >
            <div
              style={{
                width: "45%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Btn text={LABELS.SAVE} onClick={manageRecord} />
              <Btn
                text={LABELS.CANCEL}
                bgColor={COLORS.DIM_DARK_GRAY}
                onClick={() => setManageDialog(false)}
              />
            </div>
          </div>
        </UiDialog>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(LeavePlanner);
