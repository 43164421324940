import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";
// Data Tables
import jQuery from "jquery";
import "datatables.net-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "datatables.net-buttons/js/buttons.flash";
import "pdfmake/build/pdfmake";
import "pdfmake/build/vfs_fonts";

import Loader from "@components/Custom/Loader";
import AutoComplete from "@components/Custom/AutoComplete";
import TxtBox from "@components/Custom/TxtBox";
import Btn from "@components/Custom/Btn";

import { LABELS, NOTIFY, WARNINGS } from "@common/config";
import { ApiReports } from "@apihandlers/reports";
import { ApiMasters } from "@apihandlers/masters";
import { ApiHome } from "@apihandlers/home";
import { DateFunctions } from "@common/datefunctions";
import { COMMON_JS } from "@common/scripts";

const API_HANDLER_REPORTS = new ApiReports();
const API_HANDLER_MASTERS = new ApiMasters();

const $ = jQuery;

const ReportTimeSheet = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState<any>({});
  const [projects, setProjects] = useState<any>([]);
  const [clients, setClients] = useState<any>([]);

  useEffect(() => {
    if (projects.length === 0) {
      getProjects();
    }
  }, [projects]);

  const getProjects = () => {
    const args = { dropdown: true };
    API_HANDLER_MASTERS.getProjectMaster(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        const list = response.records.map((p: any) => {
          return {
            label: p.projectName,
            id: p.id,
          };
        });
        getClients(list);
      }
    });
  };

  const getClients = (projects = []) => {
    const args = {};
    API_HANDLER_MASTERS.getClients(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.clientName,
          };
        });
        setProjects(projects);
        setClients(list);
        setLoading(false);
      }
    });
  };

  const generateReportGrid = (res: any) => {
    let title = LABELS.TIME_SHEET_REPORT;
    if (clientsRef.current) {
      title += " - " + clientsRef.current.selectedValue.label;
    }
    if (projectRef.current) {
      title += " - " + projectRef.current.selectedValue.label;
    }
    $("#reportGrid").DataTable({
      dom: "Bfrtip",
      buttons: [
        {
          extend: "csv",
          className: "reportBtn",
          title: title,
          text: "<i class='fa fa-download'></i>",
        },
      ],
      paging: true,
      pageLength: 15,
      columns: res.cols,
      data: res.rows,
      order: [],
    });
  };

  const loadChart = () => {
    const fromDt = (document.getElementById("fromDt") as HTMLInputElement)
      .value;
    const toDt = (document.getElementById("toDt") as HTMLInputElement).value;
    let projectId = projectRef.current.selectedValue.id;
    let clientId = clientsRef.current.selectedValue.id;
    let category = categoryRef.current.selectedValue.id;
    const args = {
      fromDt,
      toDt,
      projectId,
      clientId,
      category,
    };
    // if (!clientId) {
    //   COMMON_JS.showNotify(props, NOTIFY.WARNING, "Please select client..!");
    //   return;
    // }
    if ($.fn.dataTable.isDataTable("#reportGrid")) {
      let table = $("#reportGrid").DataTable();
      table.destroy();
    }
    if ($("#reportGrid")) {
      $("#reportGrid").empty();
    }
    setChartData(null);
    API_HANDLER_REPORTS.getReportTimeSheet(args).then((res) => {
      // console.log("getReportTimeSheet:", args);
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setChartData(response);
        setTimeout(() => {
          generateReportGrid(response);
        }, 500);
      }
    });
  };

  const projectRef: any = useRef();
  const clientsRef: any = useRef();
  const categoryRef: any = useRef();

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => props.setDashMenu(LABELS.HOME)}>
            {LABELS.DASHBOARD}
          </li>
          <li className="dim">{" / "}</li>
          <li className="active">{LABELS.TIME_SHEET_REPORT}</li>
        </ul>
        <h4>{LABELS.TIME_SHEET_REPORT}</h4>
      </div>
      {loading ? (
        <div className="flexCenter" style={{ minHeight: 450 }}>
          <Loader />
        </div>
      ) : (
        <div className="hChart">
          <div
            id="chartForm"
            style={{
              width: "100%",
            }}
          >
            <div
              className="formGroup"
              style={{
                display: "flex",
                marginTop: 5,
                marginBottom: 10,
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  marginRight: 15,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <label
                  style={{
                    fontWeight: "500",
                    fontSize: 14,
                    marginTop: 6,
                    marginRight: 8,
                  }}
                >
                  {LABELS.FROM_DATE}
                </label>
                <TxtBox
                  type={"date"}
                  id="fromDt"
                  defaultValue={DateFunctions.getDate(
                    "today",
                    "from",
                    "YYYY-MM-DD"
                  )}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  marginRight: 15,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <label
                  style={{
                    fontWeight: "500",
                    fontSize: 14,
                    marginTop: 6,
                    marginRight: 8,
                  }}
                >
                  {LABELS.TO_DATE}
                </label>
                <TxtBox
                  type={"date"}
                  id="toDt"
                  defaultValue={DateFunctions.getDate(
                    "thismonth",
                    "to",
                    "YYYY-MM-DD"
                  )}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  marginRight: 15,
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <label
                  style={{
                    fontWeight: "500",
                    fontSize: 14,
                    marginTop: 6,
                    marginRight: 8,
                  }}
                >
                  {LABELS.CLIENT_NAME}
                </label>
                <div style={{ width: 300 }}>
                  <AutoComplete
                    id={"clients"}
                    list={clients}
                    childRef={clientsRef}
                    style={{ width: 300 }}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  marginRight: 15,
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <label
                  style={{
                    fontWeight: "500",
                    fontSize: 14,
                    marginTop: 6,
                    marginRight: 8,
                  }}
                >
                  {LABELS.PROJECT_NAME}
                </label>
                <div style={{ width: 300 }}>
                  <AutoComplete
                    id={"projects"}
                    list={projects}
                    childRef={projectRef}
                    style={{ width: 300 }}
                  />
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                marginTop: 5,
                marginBottom: 10,
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  marginRight: 15,
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <label
                  style={{
                    fontWeight: "500",
                    fontSize: 14,
                    marginTop: 6,
                    marginRight: 8,
                  }}
                >
                  {"Select Category"}
                </label>
                <div style={{ width: 300 }}>
                  <AutoComplete
                    id={"category"}
                    list={[
                      {
                        id: "monthly",
                        label: "Monthly",
                      },
                      {
                        id: "daywise",
                        label: "Day Wise",
                      },
                    ]}
                    defaultValue={{
                      id: "monthly",
                      label: "Monthly",
                    }}
                    childRef={categoryRef}
                    style={{ width: 150 }}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                }}
              >
                <Btn
                  text={LABELS.LOAD}
                  onClick={loadChart}
                  loading={!chartData}
                />
              </div>
            </div>
          </div>

          {chartData ? (
            <div className="reportGridWrapper">
              <table
                id="reportGrid"
                className="mdl-data-table width100"
                cellSpacing="0"
                cellPadding="3"
              />
            </div>
          ) : (
            <div
              className="flexCenter"
              style={{ minHeight: 400, width: "100%" }}
            >
              <Loader />
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(ReportTimeSheet);
