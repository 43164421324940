import React, { useEffect, useRef, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import {
  Replay as IconReload,
  AddBox as IconAdd,
  Edit as IconEdit,
} from "@mui/icons-material";

import TxtBox from "@components/Custom/TxtBox";
import UiDialog from "@components/Custom/UiDialog";
import Btn from "@components/Custom/Btn";
import SwitchBtn from "@components/Custom/SwitchBtn";
import Loader from "@components/Custom/Loader";

import {
  COLORS,
  ERRORS,
  LABELS,
  NOTIFY,
  SUCCESS,
  WARNINGS,
} from "@common/config";
import { ApiMasters } from "@apihandlers/masters";

import { COMMON_JS } from "@common/scripts";

const API_HANDLER_MASTERS = new ApiMasters();

let _timer = null;

const Holidays = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState<any>(null);
  const [filteredRows, setFilteredRows] = useState([]);
  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});

  useEffect(() => {
    if (loading) {
      getRows();
    }
  }, [loading]);

  const getRows = () => {
    const args = {};
    API_HANDLER_MASTERS.getHolidayMaster(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records;
        for (let i = 0; i < response.records.length; i++) {
          list[i]["slNo"] = i + 1;
          if (list[i]["activeStatus"]) {
            list[i]["activeStatusText"] = "Yes";
          } else {
            list[i]["activeStatusText"] = "No";
          }
        }
        setRows(list);
        setFilteredRows([]);
        setLoading(false);
      }
    });
  };

  const onEditRow = (row: any) => {
    setSelectedRow(row);
    setManageDialog(true);
  };

  const columns: GridColDef[] = [
    {
      field: "slNo",
      headerName: "Sl No.",
      width: 100,
      type: "number",
      align: "center",
      headerAlign: "center",
    },
    { field: "holidayDate", headerName: "Holiday Date", width: 300, headerClassName: "iconBtn" },
    { field: "holidayName", headerName: "Holiday Name", width: 300 },
    { field: "activeStatusText", headerName: "Active", width: 200 },
    {
      field: "options",
      headerName: "Options",
      width: 150,
      renderCell: (params) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => onEditRow(params.row)}
            >
              <IconEdit />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const manageRecord = () => {
    const holidayName = (
      document.getElementById("holidayName") as HTMLInputElement
    ).value.trim();
    const holidayDate = (
      document.getElementById("holidayDate") as HTMLInputElement
    ).value.trim();
    const activeStatus = (
      document.getElementById("isActive") as HTMLInputElement
    ).checked;

    // Validations
    if (!holidayName) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.PROJECT_TYPE_EMPTY);
      return;
    }
    if (!holidayDate) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.PROJECT_TYPE_EMPTY);
      return;
    }

    const args = {
      id: selectedRow.id || -1,
      holidayName,
      holidayDate,
      activeStatus,
    };
    API_HANDLER_MASTERS.manageHoliday(args).then((res) => {
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
        setSelectedRow({});
        setManageDialog(false);
        reloadGrid();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.RECORD_FAILED);
      }
    });
  };

  const deleteUser = ({ id }) => {
    // console.log("deleteUser: ", id);
    setRows(null);
    // getUsers();
  };

  const reloadGrid = () => {
    setRows(null);
    setTimeout(() => {
      getRows();
    }, 2000);
  };

  const onSearch = (e: any) => {
    const qry = e.target.value.trim().toUpperCase();
    clearTimeout(_timer);
    _timer = setTimeout(() => {
      let flist = rows.filter((r: any) => {
        return r.holidayDate.toUpperCase().indexOf(qry) > -1;
      });
      if (flist.length === 0) {
        flist = null;
      } else {
        flist = flist.map((f, i) => {
          f.slNo = i + 1;
          return f;
        });
      }
      setFilteredRows(flist);
    }, 150);
  };

  // console.log("Users: ", loading, selectedRow.active);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li className="active">{LABELS.HOLIDAY_MASTER}</li>
        </ul>
        <h4>{LABELS.HOLIDAY_MASTER}</h4>
        {/* <p>{SUB_TITLES.USERS}</p> */}
      </div>

      {rows && (
        <div className="gridOption">
          <TxtBox placeholder={LABELS.SEARCH} onKeyPress={onSearch} />
          <IconButton className="iconBtn" onClick={reloadGrid}>
            <IconReload />
          </IconButton>
          <IconButton
            className="iconBtn"
            onClick={() => {
              setManageDialog(!manageDialog);
              setSelectedRow({});
            }}
          >
            <IconAdd />
          </IconButton>
        </div>
      )}

      <div
        className="grid"
        style={{
          width: "98%",
          height: "80%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {rows ? (
          <DataGrid
            rows={
              !filteredRows ? [] : filteredRows.length > 0 ? filteredRows : rows
            }
            columns={columns}
            rowHeight={50}
            // pageSize={5}
            // rowsPerPageOptions={[5]}
            disableColumnMenu={true}
            disableSelectionOnClick={true}
          />
        ) : (
          <Loader />
        )}
      </div>

      {manageDialog && (
        <UiDialog
          title={
            selectedRow.id
              ? "Edit Holiday: " + selectedRow.holidayDate
              : "Add New Holiday"
          }
          open={true}
          size={"sm"}
          onClose={() => {
            setManageDialog(false);
          }}
        >
          <div className="formGroup">
            <label>{LABELS.HOLIDAY_DATE}</label>
            <TxtBox
              id={"holidayDate"}
              placeholder={LABELS.HOLIDAY_DATE}
              defaultValue={selectedRow.holidayDate}
              type={"date"}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.HOLIDAY_NAME}</label>
            <TxtBox
              id={"holidayName"}
              placeholder={LABELS.HOLIDAY_NAME}
              defaultValue={selectedRow.holidayName}
            />
          </div>
          <div className="formGroup">
            <SwitchBtn
              text={LABELS.ACTIVE}
              id={"isActive"}
              checked={selectedRow.id ? selectedRow.activeStatus : true}
            />
          </div>
          <div
            className="formGroup"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 25,
            }}
          >
            <div
              style={{
                width: "45%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Btn text={LABELS.SAVE} onClick={manageRecord} />
              <Btn
                text={LABELS.CANCEL}
                bgColor={COLORS.DIM_DARK_GRAY}
                onClick={() => setManageDialog(false)}
              />
            </div>
          </div>
        </UiDialog>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(Holidays);
