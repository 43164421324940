import { IApiResponse } from "@common/models";
import { API_CONFIG } from "@common/config";
import { COMMON_JS } from "@common/scripts";

interface IPrint {
  printCv: (args: any) => Promise<IApiResponse>;
  printPaySlip: (args: any) => Promise<IApiResponse>;
}

export class ApiPrint implements IPrint {
  printInvoice = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/print/invoice",
        API_CONFIG.METHODS.POST,
        args,
        true
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  printCv = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/print/cv",
        API_CONFIG.METHODS.POST,
        args,
        true
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };
  printPaySlip = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/print/payslip",
        API_CONFIG.METHODS.POST,
        args,
        true
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };
}
