import { IApiResponse } from "@common/models";
import { API_CONFIG } from "@common/config";
import { COMMON_JS } from "@common/scripts";

interface IReports {
  getOrgChart: (args: any) => Promise<IApiResponse>;
}

export class ApiReports implements IReports {
  getOrgChart = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/report/orgchart",
        API_CONFIG.METHODS.GET,
        args,
        true
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  getResourcePlan = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/report/reportresourceplan",
        API_CONFIG.METHODS.GET,
        args,
        true
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };

  getUserLogReport = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/report/userlog",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };
  
  getReportEmpLeave = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/report/reportleaves",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };
  
  getReportCost = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/report/reportcost",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };
  
  getReportInvoice = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/report/reportinvoice",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };
  
  getReportInvoiceRegister = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/report/reportinvoiceregister",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };
  
  getReportTimeSheet = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/report/reporttimesheet",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };
  
  getReportProject = async (args: any): Promise<IApiResponse> => {
    let resObj = {
      status: 500,
      response: {},
      error: "",
    };
    try {
      resObj = await COMMON_JS.callApi(
        "/v1/report/reportproject",
        API_CONFIG.METHODS.GET,
        args
      );
      return resObj;
    } catch (err: any) {
      resObj.error = err.toString();
    }
    return resObj;
  };
}
