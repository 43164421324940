import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";
// Data Tables
import jQuery from "jquery";
import "datatables.net-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import "datatables.net-buttons/js/buttons.flash";
import "pdfmake/build/pdfmake";
import "pdfmake/build/vfs_fonts";

import Loader from "@components/Custom/Loader";
import AutoComplete from "@components/Custom/AutoComplete";
import TxtBox from "@components/Custom/TxtBox";
import Btn from "@components/Custom/Btn";

import { LABELS, NOTIFY, WARNINGS } from "@common/config";
import { ApiReports } from "@apihandlers/reports";
import { DateFunctions } from "@common/datefunctions";
import { COMMON_JS } from "@common/scripts";

const API_HANDLER_REPORTS = new ApiReports();

const $ = jQuery;

const ReportResourcePlanning = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState<any>({});

  const generateReportGrid = (res: any) => {
    let title = LABELS.USER_LOG_REPORT;
    if (projectRef.current) {
      title += " - " + projectRef.current.selectedValue.label;
    }
    $("#reportGrid").DataTable({
      dom: "Bfrtip",
      buttons: [
        {
          extend: "csv",
          className: "reportBtn",
          title: title,
          text: "<i class='fa fa-download'></i>",
        },
      ],
      paging: true,
      pageLength: 15,
      columns: res.cols,
      data: res.rows,
      order: [],
    });
  };

  const loadChart = () => {
    const type = filterRef.current.selectedValue.id;
    const args = {
      type,
    };
    if ($.fn.dataTable.isDataTable("#reportGrid")) {
      let table = $("#reportGrid").DataTable();
      table.destroy();
    }
    if ($("#reportGrid")) {
      $("#reportGrid").empty();
    }
    setChartData(null);
    API_HANDLER_REPORTS.getResourcePlan(args).then((res) => {
      // console.log("getResourcePlan:", args);
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setChartData(response);
        setTimeout(() => {
          generateReportGrid(response);
        }, 500);
      }
    });
  };

  const projectRef: any = useRef();
  const filterRef: any = useRef();

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li onClick={() => props.setDashMenu(LABELS.HOME)}>
            {LABELS.DASHBOARD}
          </li>
          <li className="dim">{" / "}</li>
          <li className="active">{LABELS.RESOURCE_PLANNING_REPORT}</li>
        </ul>
        <h4>{LABELS.RESOURCE_PLANNING_REPORT}</h4>
      </div>
      {loading ? (
        <div className="flexCenter" style={{ minHeight: 450 }}>
          <Loader />
        </div>
      ) : (
        <div className="hChart">
          <div id="chartForm">
            <div
              className="formGroup"
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: 5,
                marginBottom: 10,
              }}
            >
              <div
                className="formGroup"
                style={{
                  width: "40%",
                }}
              >
                <label>{"Filter"}</label>
                <AutoComplete
                  id={"filter"}
                  list={[
                    {
                      id: "datewise",
                      label: "Date-wise",
                    },
                    {
                      id: "weekwise",
                      label: "Week-wise",
                    },
                    {
                      id: "monthwise",
                      label: "Month-wise",
                    },
                  ]}
                  defaultValue={{
                    id: "datewise",
                    label: "Date-wise",
                  }}
                  childRef={filterRef}
                />
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <Btn
                  text={LABELS.LOAD}
                  onClick={loadChart}
                  loading={!chartData}
                />
              </div>
            </div>
          </div>

          {chartData ? (
            <div className="reportGridWrapper">
              <table
                id="reportGrid"
                className="mdl-data-table width100"
                cellSpacing="0"
                cellPadding="3"
              />
            </div>
          ) : (
            <div
              className="flexCenter"
              style={{ minHeight: 400, width: "100%" }}
            >
              <Loader />
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(ReportResourcePlanning);
