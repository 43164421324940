import React, { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";

interface IList {
  label: string;
  value: any;
}

const AutoComplete = (props: any) => {
  const {
    id,
    list,
    className,
    text,
    multiple,
    disabled,
    placeholder,
    defaultValue,
    autoComplete,
    childRef,
    onChange,
  } = props;

  const [value, setValue] = useState(defaultValue || {});

  const onChangeValue = (e: any, val) => {
    // console.log("onChangeValue: ", val);
    if (!val) {
      val = {};
    }
    if (childRef) {
      childRef.current.selectedValue = val;
    }
    setValue(val);
    if (onChange) {
      onChange(e, val);
    }
  };

  useEffect(() => {
    if (childRef) {
      if (childRef) {
        childRef.current = { selectedValue: value };
      } else {
        childRef.current = {
          selectedValue: {},
        };
      }
    }
  }, [value]);

  return (
    <Autocomplete
      disablePortal
      id={id}
      options={list}
      freeSolo={autoComplete || false}
      className={"uiAutoTxt " + (className ? className : "")}
      size="small"
      disabled={disabled || false}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            id={params.id}
            label={text || ""}
            placeholder={placeholder || ""}
          />
        );
      }}
      multiple={multiple || false}
      placeholder={placeholder || ""}
      defaultValue={defaultValue}
      classes={{
        popper: "autocompletePopper",
      }}
      onChange={onChangeValue}
    />
  );
};

export default AutoComplete;
