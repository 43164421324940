import React, { useEffect, useRef, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import {
  Replay as IconReload,
  AddBox as IconAdd,
  Edit as IconEdit,
  AccountTree as IconChart,
} from "@mui/icons-material";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import HighchartsSankey from "highcharts/modules/sankey";
import highchartsOrg from "highcharts/modules/organization";
import highchartsExport from "highcharts/modules/exporting";

import TxtBox from "@components/Custom/TxtBox";
import UiDialog from "@components/Custom/UiDialog";
import Btn from "@components/Custom/Btn";
import Loader from "@components/Custom/Loader";
import AutoComplete from "@components/Custom/AutoComplete";
import OrgChart from "./OrgChart";

import {
  API_CONFIG,
  COLORS,
  ERRORS,
  LABELS,
  NOTIFY,
  SUCCESS,
  WARNINGS,
} from "@common/config";
import { ApiMasters } from "@apihandlers/masters";
import { ApiReports } from "@apihandlers/reports";

import { COMMON_JS } from "@common/scripts";

const chartIcon = require("@assets/images/icons/chart.jpeg");

const API_HANDLER_REPORTS = new ApiReports();

const API_HANDLER_MASTERS = new ApiMasters();

let _timer = null;

HighchartsSankey(Highcharts);
highchartsOrg(Highcharts);
highchartsExport(Highcharts);

const Projects = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [projectTypes, setProjectTypes] = useState([]);
  const [rows, setRows] = useState<any>(null);
  const [filteredRows, setFilteredRows] = useState([]);
  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [chartSeries, setChartSeries] = useState<any>([]);

  useEffect(() => {
    if (loading) {
      getProjectTypes();
    }
  }, [loading]);

  const getProjectTypes = () => {
    const args = {};
    API_HANDLER_MASTERS.getProjectTypeMaster(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((r) => {
          return {
            id: r.id,
            label: r.projectTypeName,
          };
        });
        getRows(list);
      }
    });
  };

  const getRows = (pTypes = []) => {
    const args = {};
    API_HANDLER_MASTERS.getProjectMaster(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records;
        for (let i = 0; i < list.length; i++) {
          list[i]["slNo"] = i + 1;
          if (list[i]["activeStatus"]) {
            list[i]["activeStatusText"] = "Yes";
          } else {
            list[i]["activeStatusText"] = "No";
          }
        }
        setProjectTypes(pTypes);
        setRows(list);
        setFilteredRows([]);
        setLoading(false);
      }
    });
  };

  const onEditRow = (row: any) => {
    setSelectedRow(row);
    setManageDialog(true);
  };

  const showOrgChart = (row: any) => {
    const projectId = row.id;
    const args = { projectId };
    setChartSeries({
      projectName: row.projectName,
    });
    API_HANDLER_REPORTS.getOrgChart(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        response["nodes"] = response["nodes"].map((n: any) => {
          if (n.image) {
            n.image = API_CONFIG.API_URL + n.image;
          }
          return n;
        });
        setChartSeries(response);
      }
    });
  };

  const columns: GridColDef[] = [
    {
      field: "slNo",
      headerName: "Sl No.",
      width: 80,
      type: "number",
      align: "center",
      headerAlign: "center",
    },
    { field: "projectId", headerName: "Project ID", width: 150 },
    { field: "projectName", headerName: "Project Name", width: 220 },
    { field: "projectCode", headerName: "Project Code", width: 150 },
    { field: "projectTypeName", headerName: "Project Type", width: 200 },
    { field: "projectStart", headerName: "Project Start", width: 150 },
    { field: "projectFinish", headerName: "Project Finish", width: 150 },
    // { field: "projectClose", headerName: "Project Close", width: 200 },
    { field: "clientName", headerName: "Client Name", width: 200 },
    { field: "projectStatus", headerName: "Status", width: 50 },
    {
      field: "options",
      headerName: "Options",
      width: 300,
      renderCell: (params) => (
        <div
          className="options"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => onEditRow(params.row)}
            >
              <IconEdit />
            </IconButton>
          </Tooltip>
          <Tooltip title={LABELS.ORG_CHART} placement="bottom">
            <div
              onClick={() => showOrgChart(params.row)}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginLeft: 10,
              }}
            >
              <img
                src={chartIcon}
                style={{
                  width: "100%",
                  height: 50,
                }}
              />
            </div>
          </Tooltip>
        </div>
      ),
    },
  ];

  const manageRecord = () => {
    const projectName = (
      document.getElementById("projectName") as HTMLInputElement
    ).value.trim();
    const projectCode = (
      document.getElementById("projectCode") as HTMLInputElement
    ).value.trim();
    const projectType = projectTypesRef.current.selectedValue.id;
    const projectStart = (
      document.getElementById("projectStart") as HTMLInputElement
    ).value.trim();
    const projectFinish = (
      document.getElementById("projectFinish") as HTMLInputElement
    ).value.trim();
    const projectClose = (
      document.getElementById("projectClose") as HTMLInputElement
    ).value.trim();
    const projectAddress1 = (
      document.getElementById("projectAddress1") as HTMLInputElement
    ).value.trim();
    const projectAddress2 = (
      document.getElementById("projectAddress2") as HTMLInputElement
    ).value.trim();
    const projectAddress3 = (
      document.getElementById("projectAddress3") as HTMLInputElement
    ).value.trim();
    const projectCountry = (
      document.getElementById("projectCountry") as HTMLInputElement
    ).value.trim();
    const projectEircode = (
      document.getElementById("projectEircode") as HTMLInputElement
    ).value.trim();
    const currency = (
      document.getElementById("currency") as HTMLInputElement
    ).value.trim();
    const projectAward = (
      document.getElementById("projectAward") as HTMLInputElement
    ).value.trim();
    const projectValue = (
      document.getElementById("projectValue") as HTMLInputElement
    ).value.trim();
    const executionBudget = (
      document.getElementById("executionBudget") as HTMLInputElement
    ).value.trim();
    const clientName = (
      document.getElementById("clientName") as HTMLInputElement
    ).value.trim();

    const clientAddress1 = (
      document.getElementById("clientAddress1") as HTMLInputElement
    ).value.trim();
    const clientAddress2 = (
      document.getElementById("clientAddress2") as HTMLInputElement
    ).value.trim();
    const clientAddress3 = (
      document.getElementById("clientAddress3") as HTMLInputElement
    ).value.trim();
    const clientCountry = (
      document.getElementById("clientCountry") as HTMLInputElement
    ).value.trim();
    const clientEircode = (
      document.getElementById("clientEircode") as HTMLInputElement
    ).value.trim();

    const contactName = (
      document.getElementById("contactName") as HTMLInputElement
    ).value.trim();
    const contactAddress1 = (
      document.getElementById("contactAddress1") as HTMLInputElement
    ).value.trim();
    const contactAddress2 = (
      document.getElementById("contactAddress2") as HTMLInputElement
    ).value.trim();
    const contactAddress3 = (
      document.getElementById("contactAddress3") as HTMLInputElement
    ).value.trim();
    const contactCountry = (
      document.getElementById("contactCountry") as HTMLInputElement
    ).value.trim();
    const contactEircode = (
      document.getElementById("contactEircode") as HTMLInputElement
    ).value.trim();
    const contactNo = (
      document.getElementById("contactNo") as HTMLInputElement
    ).value.trim();
    const contactEmailid = (
      document.getElementById("contactEmailid") as HTMLInputElement
    ).value.trim();

    const projectCounty = (
      document.getElementById("projectCounty") as HTMLInputElement
    ).value.trim();
    const clientCounty = (
      document.getElementById("clientCounty") as HTMLInputElement
    ).value.trim();
    const contactCounty = (
      document.getElementById("contactCounty") as HTMLInputElement
    ).value.trim();

    const projectStatus = projectStatusRef.current.selectedValue.id;

    // Validations
    if (!projectName) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, WARNINGS.PROJECT_NAME_EMPTY);
      return;
    }
    if (!clientName) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Client Name is mandatory..!"
      );
      return;
    }
    if (!projectName) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Project Name is mandatory..!"
      );
      return;
    }
    if (!projectAddress1) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Project Address 1 is mandatory..!"
      );
      return;
    }
    if (!clientAddress1) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Client Address 1 is mandatory..!"
      );
      return;
    }
    if (!contactName) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Contact Name is mandatory..!"
      );
      return;
    }
    if (!projectType) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Project Type is mandatory..!"
      );
      return;
    }
    if (!projectStatus) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Project Status is mandatory..!"
      );
      return;
    }
    if (!contactAddress1) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        "Contact Address 1 is mandatory..!"
      );
      return;
    }

    const args = {
      id: selectedRow.id || -1,
      projectName,
      projectCode,
      projectTypeId: projectType,
      projectStart,
      projectFinish,
      projectClose,
      projectAddress1,
      projectAddress2,
      projectAddress3,
      projectCounty,
      projectCountry,
      projectEircode,
      currency,
      projectAward,
      projectValue,
      executionBudget,
      clientName,
      clientAddress1,
      clientAddress2,
      clientAddress3,
      clientCounty,
      clientCountry,
      clientEircode,
      contactName,
      contactAddress1,
      contactAddress2,
      contactAddress3,
      contactCounty,
      contactCountry,
      contactEircode,
      contactNo,
      contactEmailid,
      projectStatus,
    };
    API_HANDLER_MASTERS.manageProject(args).then((res) => {
      if (res.status === 200) {
        COMMON_JS.showNotify(props, NOTIFY.SUCCESS, SUCCESS.RECORD_SUCCESS);
        setSelectedRow({});
        setManageDialog(false);
        reloadGrid();
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, ERRORS.RECORD_FAILED);
      }
    });
  };

  const deleteUser = ({ id }) => {
    // console.log("deleteUser: ", id);
    setRows(null);
    // getUsers();
  };

  const reloadGrid = () => {
    setRows(null);
    setTimeout(() => {
      getRows();
    }, 2000);
  };

  const onSearch = (e: any) => {
    const qry = e.target.value.trim().toUpperCase();
    clearTimeout(_timer);
    _timer = setTimeout(() => {
      let flist = rows.filter((r: any) => {
        return r.projectName.toUpperCase().indexOf(qry) > -1;
      });
      if (flist.length === 0) {
        flist = null;
      } else {
        flist = flist.map((f, i) => {
          f.slNo = i + 1;
          return f;
        });
      }
      setFilteredRows(flist);
    }, 150);
  };

  const projectStatusRef: any = useRef();
  const projectTypesRef: any = useRef();

  console.log("Projects: ", chartSeries);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li className="active">{LABELS.PROJECTS}</li>
        </ul>
        <h4>{LABELS.PROJECTS}</h4>
      </div>

      {rows && (
        <div className="gridOption">
          <TxtBox placeholder={LABELS.SEARCH} onKeyPress={onSearch} />
          <IconButton className="iconBtn" onClick={reloadGrid}>
            <IconReload />
          </IconButton>
          <IconButton
            className="iconBtn"
            onClick={() => {
              setManageDialog(!manageDialog);
              setSelectedRow({});
            }}
          >
            <IconAdd />
          </IconButton>
        </div>
      )}

      <div
        className="grid"
        style={{
          width: "98%",
          height: "80%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {rows ? (
          <DataGrid
            rows={
              !filteredRows ? [] : filteredRows.length > 0 ? filteredRows : rows
            }
            columns={columns}
            rowHeight={50}
            // pageSize={5}
            // rowsPerPageOptions={[5]}
            disableColumnMenu={true}
            disableSelectionOnClick={true}
          />
        ) : (
          <Loader />
        )}
      </div>

      {chartSeries.projectName && (
        <UiDialog
          title={" "}
          open={true}
          size={"md"}
          onClose={() => {
            setChartSeries({});
          }}
        >
          <OrgChart
            title={"Organization Chart - " + chartSeries.projectName}
            chartSeries={chartSeries}
            Highcharts={Highcharts}
            HighchartsReact={HighchartsReact}
          />
        </UiDialog>
      )}

      {manageDialog && (
        <UiDialog
          title={
            selectedRow.id
              ? "Edit Project: " + selectedRow.projectName
              : "Add New Project"
          }
          open={true}
          size={"sm"}
          onClose={() => {
            setManageDialog(false);
          }}
        >
          <div className="formGroup">
            <label style={{ color: "red" }}>{LABELS.PROJECT_NAME} *</label>
            <TxtBox
              id={"projectName"}
              placeholder={LABELS.PROJECT_NAME}
              defaultValue={selectedRow.projectName}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.PROJECT_CODE}</label>
            <TxtBox
              id={"projectCode"}
              placeholder={LABELS.PROJECT_CODE}
              defaultValue={selectedRow.projectCode}
            />
          </div>
          <div className="formGroup">
            <label style={{ color: "red" }}>{LABELS.PROJECT_TYPE} *</label>
            <AutoComplete
              id={"projectTypes"}
              list={projectTypes}
              childRef={projectTypesRef}
              defaultValue={
                selectedRow
                  ? selectedRow.projectTypeId
                    ? {
                        id: selectedRow.projectTypeId,
                        label: selectedRow.projectTypeName,
                      }
                    : null
                  : null
              }
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.PROJECT_START}</label>
            <TxtBox
              id={"projectStart"}
              placeholder={LABELS.PROJECT_START}
              defaultValue={selectedRow.projectStart}
              type={"date"}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.PROJECT_FINISH}</label>
            <TxtBox
              id={"projectFinish"}
              placeholder={LABELS.PROJECT_FINISH}
              defaultValue={selectedRow.projectFinish}
              type={"date"}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.PROJECT_CLOSE_DATE}</label>
            <TxtBox
              id={"projectClose"}
              placeholder={LABELS.PROJECT_CLOSE_DATE}
              defaultValue={selectedRow.projectClose}
              type={"date"}
            />
          </div>
          <div className="formGroup">
            <label style={{ color: "red" }}>{LABELS.PROJECT_ADDRESS_1} *</label>
            <TxtBox
              id={"projectAddress1"}
              placeholder={LABELS.PROJECT_ADDRESS_1}
              defaultValue={selectedRow.projectAddress1}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.PROJECT_ADDRESS_2}</label>
            <TxtBox
              id={"projectAddress2"}
              placeholder={LABELS.PROJECT_ADDRESS_2}
              defaultValue={selectedRow.projectAddress2}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.PROJECT_ADDRESS_3}</label>
            <TxtBox
              id={"projectAddress3"}
              placeholder={LABELS.PROJECT_ADDRESS_3}
              defaultValue={selectedRow.projectAddress3}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.PROJECT_COUNTY}</label>
            <TxtBox
              id={"projectCounty"}
              placeholder={LABELS.PROJECT_COUNTY}
              defaultValue={selectedRow.projectCounty}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.PROJECT_COUNTRY}</label>
            <TxtBox
              id={"projectCountry"}
              placeholder={LABELS.PROJECT_COUNTRY}
              defaultValue={selectedRow.projectCountry}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.PROJECT_EIR_CODE}</label>
            <TxtBox
              id={"projectEircode"}
              placeholder={LABELS.PROJECT_EIR_CODE}
              defaultValue={selectedRow.projectEircode}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.CURRENCY}</label>
            <TxtBox
              id={"currency"}
              placeholder={LABELS.CURRENCY}
              defaultValue={selectedRow.currency}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.PROJECT_AWARD_DATE}</label>
            <TxtBox
              id={"projectAward"}
              placeholder={LABELS.PROJECT_AWARD_DATE}
              defaultValue={selectedRow.projectAward}
              type={"date"}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.PROJECT_VALUE}</label>
            <TxtBox
              id={"projectValue"}
              placeholder={LABELS.PROJECT_VALUE}
              defaultValue={selectedRow.projectValue}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.BUDGET_FOR_EXECUTION}</label>
            <TxtBox
              id={"executionBudget"}
              placeholder={LABELS.BUDGET_FOR_EXECUTION}
              defaultValue={selectedRow.executionBudget}
            />
          </div>

          <div className="formGroup">
            <label style={{ color: "red" }}>{LABELS.CLIENT_NAME} *</label>
            <TxtBox
              id={"clientName"}
              placeholder={LABELS.CLIENT_NAME}
              defaultValue={selectedRow.clientName}
            />
          </div>
          <div className="formGroup">
            <label style={{ color: "red" }}>{LABELS.CLIENT_ADDRESS_1} *</label>
            <TxtBox
              id={"clientAddress1"}
              placeholder={LABELS.CLIENT_ADDRESS_1}
              defaultValue={selectedRow.clientAddress1}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.CLIENT_ADDRESS_2}</label>
            <TxtBox
              id={"clientAddress2"}
              placeholder={LABELS.CLIENT_ADDRESS_2}
              defaultValue={selectedRow.clientAddress2}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.CLIENT_ADDRESS_3}</label>
            <TxtBox
              id={"clientAddress3"}
              placeholder={LABELS.CLIENT_ADDRESS_3}
              defaultValue={selectedRow.clientAddress3}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.CLIENT_COUNTY}</label>
            <TxtBox
              id={"clientCounty"}
              placeholder={LABELS.CLIENT_COUNTY}
              defaultValue={selectedRow.clientCounty}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.CLIENT_COUNTRY}</label>
            <TxtBox
              id={"clientCountry"}
              placeholder={LABELS.CLIENT_COUNTRY}
              defaultValue={selectedRow.clientCountry}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.CLIENT_EIR_CODE}</label>
            <TxtBox
              id={"clientEircode"}
              placeholder={LABELS.CLIENT_EIR_CODE}
              defaultValue={selectedRow.clientEircode}
            />
          </div>

          <div className="formGroup">
            <label style={{ color: "red" }}>{LABELS.CONTACT_NAME} *</label>
            <TxtBox
              id={"contactName"}
              placeholder={LABELS.CONTACT_NAME}
              defaultValue={selectedRow.contactName}
            />
          </div>
          <div className="formGroup">
            <label style={{ color: "red" }}>{LABELS.CONTACT_ADDRESS_1} *</label>
            <TxtBox
              id={"contactAddress1"}
              placeholder={LABELS.CONTACT_ADDRESS_1}
              defaultValue={selectedRow.contactAddress1}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.CONTACT_ADDRESS_2}</label>
            <TxtBox
              id={"contactAddress2"}
              placeholder={LABELS.CONTACT_ADDRESS_2}
              defaultValue={selectedRow.contactAddress2}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.CONTACT_ADDRESS_3}</label>
            <TxtBox
              id={"contactAddress3"}
              placeholder={LABELS.CONTACT_ADDRESS_3}
              defaultValue={selectedRow.contactAddress3}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.CONTACT_COUNTY}</label>
            <TxtBox
              id={"contactCounty"}
              placeholder={LABELS.CONTACT_COUNTY}
              defaultValue={selectedRow.contactCounty}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.CONTACT_COUNTRY}</label>
            <TxtBox
              id={"contactCountry"}
              placeholder={LABELS.CONTACT_COUNTRY}
              defaultValue={selectedRow.contactCountry}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.CONTACT_EIR_CODE}</label>
            <TxtBox
              id={"contactEircode"}
              placeholder={LABELS.CONTACT_EIR_CODE}
              defaultValue={selectedRow.contactEircode}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.CONTACT_NUMBER}</label>
            <TxtBox
              id={"contactNo"}
              placeholder={LABELS.CONTACT_NUMBER}
              defaultValue={selectedRow.contactNo}
            />
          </div>
          <div className="formGroup">
            <label>{LABELS.CONTACT_EMAIL_ID}</label>
            <TxtBox
              id={"contactEmailid"}
              placeholder={LABELS.CONTACT_EMAIL_ID}
              defaultValue={selectedRow.contactEmailid}
            />
          </div>

          <div className="formGroup">
            <label style={{ color: "red" }}>{LABELS.PROJECT_STATUS} *</label>
            <AutoComplete
              id={"projectStatus"}
              list={[
                {
                  id: "Active",
                  label: "Active",
                },
                {
                  id: "On Hold",
                  label: "On Hold",
                },
                {
                  id: "Closed",
                  label: "Closed",
                },
              ]}
              childRef={projectStatusRef}
              defaultValue={
                selectedRow
                  ? selectedRow.projectStatus
                    ? {
                        id: selectedRow.projectStatus,
                        label: selectedRow.projectStatus,
                      }
                    : null
                  : null
              }
            />
          </div>
          <div
            className="formGroup"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 25,
            }}
          >
            <div
              style={{
                width: "45%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Btn text={LABELS.SAVE} onClick={manageRecord} />
              <Btn
                text={LABELS.CANCEL}
                bgColor={COLORS.DIM_DARK_GRAY}
                onClick={() => setManageDialog(false)}
              />
            </div>
          </div>
        </UiDialog>
      )}
    </React.Fragment>
  );
};

export default withSnackbar(Projects);
