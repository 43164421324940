import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Paper,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import Draggable from "react-draggable";

const PaperComponent = (props) => {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
};

const UiDialog = (props: any) => {
  const { title, open, size, draggable, children, bottom, onClose } = props;
  return (
    <Dialog
      className={"uiDialog"}
      open={open || false}
      PaperComponent={PaperComponent}
      fullWidth={true}
      maxWidth={size || "md"}
      onClose={onClose}
    >
      <DialogTitle>
        {title}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      {bottom && <DialogActions>{bottom}</DialogActions>}
    </Dialog>
  );
};

export default UiDialog;
