const DEVELOPMENT = false;

export const CONFIG = {
  SITE_NAME: "Hiwin - Manage",
  VERSION: "V1.0.4",
  // DON'T CHANGE USER ROLES VALUES
  USER_ROLES: {
    HR: 10,
    SeniorQuantitySurveyor: 11,
    QuantitySurveyor: 12,
    PlanningEngineer: 13,
    Director: 14,
    Admin: 15,
  },
};

export const API_CONFIG = {
  API_URL: DEVELOPMENT
    ? "http://localhost:8000"
    : "https://api.hiwinsystems.net",
  METHODS: {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    DELETE: "DELETE",
  },
  TIMEOUT: 60 * 1000,
};

export const FORMATS = {
  SQL_DATE: "YYYY-MM-DD HH:mm:ss",
  SQL_DATE_ONLY: "YYYY-MM-DD",
  DATE: "DD-MM-YYYY HH:mm:ss",
  DATE_ONLY: "DD-MM-YYYY",
  DATE_PICKER_FORMAT: "dd-MM-yyyy",
  REPORT_TITLE_DATE: "MMM DD, YYYY",
  REPORT_DATE_WITH_TIME: "MMM DD, YYYY HH:mm:ss",
  MONTH_ONLY: "MMMM",
  MONTH_AND_YEAR_ONLY: "MMMM - YYYY",
  GRAPH_TIME: "HH:mm",
};

export const RESOURCE_SLOTS = {
  SLOT_1: "08:00",
  SLOT_2: "09:00",
  SLOT_3: "10:00",
  SLOT_4: "11:00",
  SLOT_5: "12:00",
  SLOT_6: "13:00",
  SLOT_7: "14:00",
  SLOT_8: "15:00",
  SLOT_9: "16:00",
  SLOT_10: "17:00",
  SLOT_11: "18:00",
  SLOT_12: "19:00",
  SLOT_13: "20:00",
};

export const COLORS = {
  PRIMARY: "#1363DF",
  SECONDARY: "#06283D",
  WHITE: "#fff",
  DIM_GRAY: "#e1e1e1",
  DIM_DARK_GRAY: "#6e6e6e",
};

export const DEFAULTS = {
  BTN_TYPE: "outlined",
  DIALOG_SIZE: "sm",
  MAP_TYPE: 1,
};

export const LOCAL_STORAGE = {
  USER_INFO: "hUserInfo",
  ACCESS_TOKEN: "hToken",
  REMEMBER_ME: "hRemember",
};

export const SUB_TITLES = {
  USERS: "Manage Users",
};

export const LABELS = {
  MINUTES_OF_MEETING: "Minutes of Meeting",
  WORK_PACKAGE: "Work Package",
  WORK_PACKAGES: "Work Packages",
  PL: "Paid Leaves",
  SL: "Sick Leaves",
  LEAVE_PLANNER_APPROVE: "Leave Approval",
  ADMIN_COMMENTS: "Admin Comments",
  ORG_CHART: "Organization Chart",
  TIME_SHEET_APPROVE: "Timesheet Approve",
  NOTES: "Notes",
  TERMS: "Terms",
  DUE_DATE: "Due Date",
  PRINT: "Print",
  INVOICES: "Invoices",
  INVOICE_PRINT: "Invoice Print",
  SELECT_TAX: "Select Tax",
  DATE_OF_INVOICE: "Invoice Date",
  PRINT_PAYSLIP: "Print Payslip",
  COMMENTS: "Comments",
  PRESENT_DAYS: "Present Days",
  ABSENT_DAYS: "Absent Days",
  TOTAL_WORKING_DAYS: "Total Working Days",
  PAY_ROLL: "Pay Roll",
  NET_PAY: "Net Pay",
  TDS: "TDS",
  PROFESSIONAL_TAX: "Professional Tax",
  TOTAL_SALARY: "Total Salary",
  MONTH_END_DATE: "Month End Date",
  MONTH_START_DATE: "Month Start Date",
  PHOTO: "Photo",
  LEAVE_PLANNER: "Leave Planner",
  PRINT_CV: "Print CV",
  SELECTED_PROF_HISTORY: "Selected Profile History",
  RECENT_PROF_HISTORY: "Recent Profile History",
  KEY_EXPERIENCE: "Key Experience",
  COUNTRIES_WORKED_IN: "Countries Worked In",
  KEY_SKILLS: "Key Skills",
  COURSE: "Course",
  QUALIFICATION: "Qualification",
  PROFESSION: "Profession",
  ADD_CV: "Add/Edit CV",
  PROJECT_COUNTY: "Project County",
  CLIENT_COUNTY: "Client County",
  CONTACT_COUNTY: "Contact County",
  PROJECT_VALUE: "Project Value",
  BUDGET_FOR_EXECUTION: "Budget For Execution",
  PROJECT_AWARD_DATE: "Project Award Date",
  CONTACT_EMAIL_ID: "Contact Email ID",
  CONTACT_EIR_CODE: "Contact EIR Code",
  CONTACT_COUNTRY: "Contact Country",
  CONTACT_ADDRESS_1: "Contact Address 1",
  CONTACT_ADDRESS_2: "Contact Address 2",
  CONTACT_ADDRESS_3: "Contact Address 3",
  CONTACT_NAME: "Contact Name",
  CLIENT_EIR_CODE: "Client EIR Code",
  CLIENT_COUNTRY: "Client Country",
  CLIENT_ADDRESS_1: "Client Address 1",
  CLIENT_ADDRESS_2: "Client Address 2",
  CLIENT_ADDRESS_3: "Client Address 3",
  PROJECT_EIR_CODE: "Project EIR Code",
  PROJECT_COUNTRY: "Project Country",
  PROJECT_ADDRESS_1: "Project Address 1",
  PROJECT_ADDRESS_2: "Project Address 2",
  PROJECT_ADDRESS_3: "Project Address 3",
  PROJECT_CLOSE_DATE: "Project Close Date",
  PROJECT_START: "Project Start",
  PROJECT_FINISH: "Project Finish",
  CLIENT_NAME: "Client Name",
  BLOOD_GROUP: "Blood Group",
  PERMANENT_ADDRESS: "Permanent Address",
  PRESENT_ADDRESS: "Present Address",
  PERSONAL_EMAIL: "Personal Email",
  OFFICE_EMAIL: "Office Email",
  E_CONTACT_NUMBER: "Emergency Contact No.",
  RESOURCE_PLANNING: "Resource Planning",
  TIME_SHEET: "Time Sheet Booking",
  TIME_SHEET_APPROVAL: "Approve Time Sheet",
  PAY_SLIP: "Pay Slip",
  INVOICE: "Invoice",
  DATE: "Date",
  FROM_DATE: "From",
  TO_DATE: "To",
  EMPLOYEE: "Employee",
  NORMAL_HRS: "Normal Hours",
  DESIGNATION_MASTER: "Designation Master",
  DESIGNATION: "Designation",
  RESPONSIBILITY_MASTER: "Responsibility Master",
  RESPONSIBILITY: "Responsibility",
  LEVEL: "Level",
  PROJECT_TYPE_MASTER: "Project Type Master",
  PROJECT_TYPE: "Project Type",
  TASK_MASTER: "Task Master",
  TASK: "Task",
  HOLIDAY_MASTER: "Holiday Master",
  HOLIDAY: "Holiday",
  HOLIDAY_DATE: "Holiday Date",
  HOLIDAY_NAME: "Holiday Name",
  EMPLOYEE_MASTER: "Employees List",
  EMPLOYEE_ID: "Employee ID",
  EMPLOYEE_NAME: "Employee Name",

  STATUS: "Status",
  SCHEDULE_UPDATE_FREQUENCY: "Schedule Update Frequency",
  RISKS_REGISTER: "Risks Register",
  NEW_PASSWORD: "New Password",
  CURRENT_PASSWORD: "Current Password",
  SETTINGS: "Settings",
  CONSTRAINTS: "Constraints",
  SPENT_HRS: "Spent Hours",
  TASK_OWNER: "Task Owner",
  EMP_ID: "Employee ID",
  SELECT_DATE: "Status/Data Date",
  RESOURCE_PLANNING_REPORT: "Resource Planning Report",
  PROJECTS_REPORT: "Projects Report",
  TIME_SHEET_REPORT: "Time Sheet Report",
  INVOICE_REPORT: "Project Invoice Report",
  INVOICE_REGISTER: "Invoice Register",
  COST_REPORT: "Cost Report",
  APPROVAL_LOG_REPORT: "Approval Log Report",
  USER_LOG_REPORT: "User Log Report",
  EMPLOYEE_LEAVES_REPORT: "Employee Leaves Report",
  VIEW: "View",
  APPROVE: "Approve",
  REJECT: "Reject",
  APPROVE_FILE: "Approve File",
  COST: "Cost",
  CURRENCY: "Currency",
  UNIT: "Unit",
  QTY: "Qty.",
  EXPORT_TO_PDF: "Export To PDF",
  SIX_WEEKS_VIEW: "6 Weeks View",
  ONE_MONTH_VIEW: "1 Month View",
  SHOW_ALL: "Show All",
  TASK_NAME: "Discipline",
  REPORTS: "Dashboard",
  PROJECT_MANAGER: "Project Manager",
  PROJECT_CHART: "Project Chart",
  PROJECT_CRITICAL_PATH: "Project Critical Path",
  WEEK_LOOK_AHEAD_SCHEDULE: "Week Look Ahead Schedule",
  PROJECT_REMAINING_DURATION: "Project Remaining Duration",
  EXPAND_ALL: "Expand All",
  PERCENTAGE: "Percentage",
  CRITICAL_PATH: "Critical Path",
  NEAR_TO_CRITICAL_PATH: "Near To Critical Path",
  PROJECT_CASH_FLOW: "Project Cash Flow",
  PROGRESS_CURVE: "Progress S Curve",
  MAN_HOURS_DISTRIBUTION: "Man Hours Distribution",
  RESOURCE_TABLE: "Resource Table",
  FINISH_DATE: "Finish Date",
  START_DATE: "Start Date",
  EDIT_CHART: "Edit Chart",
  PROJECT_OVERVIEW: "Project Overview",
  PROJECT_PROGRESS_STATUS: "Project Progress Status vs Earned",
  MAN_HOURS_PLAN: "Man Hours Distribution",
  RESOURCE_HISTOGRAM: "Resource Histogram",
  VIEW_PROJECT_CHARTER: "View Project Charter",
  PROJECT_CHARTER: "Project Charter",
  CONFIRM: "Confirm",
  LOAD: "Load",
  CONTACT_NUMBER: "Contact Number",
  INCHARGE: "Incharge",
  ADDRESS_1: "Address 1",
  ADDRESS_2: "Address 2",
  ZIPCODE: "Zipcode",
  UPLOAD: "Upload",
  SELECT_FILE: "Choose File",
  FILE_UPLOAD_TYPE: "File Upload Type",
  SUPERVISOR: "Supervisor",
  PROJECT_NAME: "Project Name",
  PROJECT_CODE: "Project Code",
  SELECT_PROJECT: "Select Project",
  DELETE: "Delete",
  EDIT: "Edit",
  SAVE: "Save",
  CANCEL: "Cancel",
  HOME: "Home",
  PROJECTS: "Projects List",
  DASHBOARD: "Dashboard",
  GANTT_CHART: "Gantt Chart",
  USERS: "Users List",
  ORGANIZATION: "Organization",
  ORGANIZATIONS: "Organizations",
  LOGOUT: "Logout",
  FIRST_NAME: "First Name",
  LAST_NAME: "Last Name",
  USER_NAME: "Username",
  PASSWORD: "Password",
  LOGIN: "Login",
  REMEMBER_ME: "Remember Me",
  MENU: "Menu",
  SEARCH: "Search",
  EMAIL_ID: "Email ID",
  PHONE: "Phone Number",
  USER_TYPE: "User Type",
  ACTIVE: "Active",
  INACTIVE: "In Active",
  CHARGEABLE: "Chargeable",
  DIRECTOR: "Director",
  PROJECT_STATUS: "Project Status",
};

export const TITLES = {
  PROP_TYPES: "TYPES OF PROPERTIES",
  TOP_CITIES: "Top Cities In India",
  MOST_VIEWED: "Most Viewed",
  NEW_LISTINGS: "New Listings",
  LOCATION_INFO: "Location Information",
  LANDSCAPE: "Landscape",
  PAPERS: "Papers",
  INTERNAL_AMENITIES: "Internal Amenities",
  SURROUNDING_AMENITIES: "Surrounding Amenities",
  WEATHER: "Weather",
  LAST_VALUES: "Land value past 10 years",
  ADD_NEW_PROPERTY: "Add New Property",
  SCORES: "Scores",
  PHOTOS_AND_VIDEOS: "Photos & Videos",
  PROPERTY_LOCATION: "Property Location",
};

export const TITLE_INFO = {
  PROP_TYPES:
    "The type of properties available for your dream home or investment.",
  TOP_CITIES: "Find Places to Buy in these Cities",
  MOST_VIEWED: "The properties which are most viewed recently",
};

export const NOTIFY = {
  WARNING: "warning",
  SUCCESS: "success",
  ERROR: "error",
  AUTO_HIDE_DURATION: 3000,
};

export const CONFIRMS = {
  SURE_TO_LOGOUT: "Are you sure want to logout..?",
  DELETE_USER: "Are you sure want to delete this user..?",
  SURE_TO_APPROVE: "Are you sure want to approve this..?",
  SURE_TO_SAVE: "Are you sure want to save this..?",
};

export const SUCCESS = {
  RECORD_SUCCESS: "Record Saved Successfully..!",

  PASSWORD_CHANGED: "Password Changed Successfully..!",
  LOGIN_SUCCESS: "Login Successful, Redirecting to home page..!",
  LOGOUT_SUCCESS: "Logout successful, Redirecting to login page..!",
  SIGNUP_SUCCESS: "Signup successful..!",
  PROPERTY_SAVED: "Property Saved Successfully..!",
  FILE_UPLOADED: "File Uploaded Successfully..!",
  PROJECT_SUCCESS: "Project Saved Successfully..!",
  ORGANIZATION_SUCCESS: "Organization Saved Successfully..!",
  USER_SUCCESS: "User Saved Successfully..!",
  CHART_INFO_UPDATED: "Chart Info Updated Successfully..!",
  FILE_APPROVED: "File Approved Successfully..!",
};

export const WARNINGS = {
  WORK_PACKAGE_EMPTY: "Work Package is Mandatory..!",

  PROJECT_TYPE_EMPTY: "Project Type Name is Mandatory..!",
  DESIGNATION_NAME_EMPTY: "Designation Name is Mandatory..!",
  RESPONSIBILITY_NAME_EMPTY: "Responsibility Name is Mandatory..!",
  EMP_IS_EMPTY: "Employee is mandatory..!",

  BOTH_PASSWORDS_SAME: "Current & New Password should not be same..!",
  NPWD_LENGTH_SMALL:
    "New Password Length should be greater than 6 characters..!",
  CPWD_EMPTY: "Current Password is empty..!",
  NPWD_EMPTY: "New Password is empty..!",
  DATE_EMPTY: "Please select date..!",
  TASK_EMPTY: "Please select Task..!",
  FILE_NOT_FOUND: "File Not Found..!",
  INVALID_EMAIL: "Invalid Email ID..!",
  USER_TYPE_EMPTY: "Please Select User Type..!",
  USER_NAME_IS_EMPTY: "Please Enter User Name..!",
  FIRST_NAME_IS_EMPTY: "Please Enter First Name..!",
  CONTACT_NO_IS_EMPTY: "Please Enter Incharge Contact Number..!",
  INCHARGE_IS_EMPTY: "Please Enter Incharge Name..!",
  ORG_NAME_IS_EMPTY: "Please Enter Organization Name..!",
  ZIPCODE_IS_EMPTY: "Please Enter Zipcode..!",
  ADDRESS_IS_EMPTY: "Please Enter Address..!",
  SUPERVISOR_EMPTY: "Please select Supervisor..!",
  ORG_EMPTY: "Please select Organization..!",
  PROJECT_NAME_EMPTY: "Please enter the Project Name..!",
  PROJECT_EMPTY: "Please select project..!",
  FILE_EMPTY: "Please select file..!",
  USERNAME_IS_EMPTY: "Username is mandatory..!",
  PASSWORD_IS_EMPTY: "Password is mandatory..!",
  PROPERTY_TYPE_EMPTY: "Please select property type..!",
  STATE_EMPTY: "Please select state..!",
  CITY_EMPTY: "Please select city..!",
  AREA_EMPTY: "Please select Area..!",
  ADDRESS_EMPTY: "Please enter address..!",
  PROPERTY_NAME_EMPTY: "Please enter address..!",
  PROPERTY_DESC_EMPTY: "Please enter property description..!",
  PROPERTY_OWNER_EMPTY: "Please enter property owner name..!",
};

export const ERRORS = {
  RECORD_FAILED: "Record Not Saved..!",

  LOGIN_FAILED: "Login Failed: Invalid Username or Password..!",
  LOGOUT_FAILED: "Logout failed..!",
  SCORE_FAILED: "Score Calculations Failed..!",
  FILE_NOT_UPLOADED: "File Not Uploaded Successfully..!",
  PROJECT_FAILED: "Project Not Saved..!",
  ORGANIZATION_FAILED: "Organization Not Saved..!",
  USER_FAILED: "User Not Saved..!",
  CHART_INFO_NOT_UPDATED: "Chart Info Not Updated Successfully..!",
  FILE_APPROVE_FAILED: "File Not Approved Successfully..!",
};

export const BTN_VARIENTS = {
  TEXT: "text",
  OUTLINED: "outlined",
  CONTAINED: "contained",
};

export const UNITS = {
  PRICE: " €",
};

export const LEAVE_STATUS = {
  APPROVE: "Approve",
  APPLIED: "Applied",
  REJECT: "Reject",
};
