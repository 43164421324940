import React, { useEffect, useRef, useState } from "react";
import { withSnackbar } from "notistack";
import DataGrid, {
  Column,
  Editing,
  Paging,
  Scrolling,
  GroupPanel,
  SearchPanel,
  Grouping,
  Toolbar,
  Item,
  Button,
  Lookup,
} from "devextreme-react/data-grid";
import Guid from "devextreme/core/guid";

import Loader from "@components/Custom/Loader";
import AutoComplete from "@components/Custom/AutoComplete";
import TxtBox from "@components/Custom/TxtBox";
import Btn from "@components/Custom/Btn";

import "./styles/rplanning.css";

import { LABELS, NOTIFY, RESOURCE_SLOTS } from "@common/config";
import { ApiHome } from "@apihandlers/home";
import { ApiMasters } from "@apihandlers/masters";
import { DateFunctions } from "@common/datefunctions";
import { COMMON_JS } from "@common/scripts";

const API_HANDLER_HOME = new ApiHome();
const API_HANDLER_MASTER = new ApiMasters();

const ResourcePlanning = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState<any>(null);
  const [newRowPosition, setNewRowPosition] = React.useState("viewportTop");
  const [changes, setChanges] = React.useState([]);
  const [editRowKey, setEditRowKey] = React.useState(null);
  const [projects, setProjects] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [responsibilities, setResponsibilities] = useState([]);
  const [spares, setSpares] = useState([]);
  // const [projectTypes, setProjectTypes] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    if (loading) {
      getProjects();
    }
  }, [loading]);

  const getProjects = () => {
    const args = { dropdown: true, projectStatus: "Active" };
    API_HANDLER_MASTER.getProjectMaster(args)
      .then((res: any) => {
        // console.log("getProjects: res:", res);
        const { status, response } = res;
        if (status === 200) {
          const list = response.records.map((r: any) => {
            return {
              id: r.id,
              label: r.projectName,
            };
          });
          getTasks(list);
        }
      })
      .catch((err) => {
        // console.log("getProjects: ", err);
      });
  };

  const getTasks = (projectList = []) => {
    const args: any = {
      activeStatus: "true",
    };
    API_HANDLER_HOME.getTasks(args).then((res: any) => {
      const { status, response } = res;
      if (status === 200) {
        const list = response.records.map((t) => {
          return {
            id: t.id,
            name: t.taskTypeName,
          };
        });
        getResponsibilities(projectList, list);
      }
    });
  };

  const getResponsibilities = (projectList = [], tasksList = []) => {
    const args: any = {};
    API_HANDLER_HOME.getResponsibilities(args).then((res: any) => {
      const { status, response } = res;
      if (status === 200) {
        getSpares(projectList, tasksList, response.records);
      }
    });
  };

  const getSpares = (projectList = [], tasksList = [], resList = []) => {
    const args: any = {};
    API_HANDLER_HOME.getSpares(args).then((res: any) => {
      const { status, response } = res;
      if (status === 200) {
        getEmpDrodown(projectList, tasksList, resList, response.records);
      }
    });
  };

  const getProjectTypes = (
    projectList = [],
    tasksList = [],
    resList = [],
    sparesList = []
  ) => {
    const args: any = {};
    API_HANDLER_HOME.getProjectTypes(args).then((res: any) => {
      const { status, response } = res;
      if (status === 200) {
        const list = response.records.map((r: any) => {
          return {
            id: r.id,
            name: r.projectTypeName,
          };
        });
        getEmpDrodown(projectList, tasksList, resList, sparesList, list);
      }
    });
  };

  const getEmpDrodown = (
    projectList = [],
    tasksList = [],
    resList = [],
    sparesList = [],
    projectTypes = []
  ) => {
    const args: any = {};
    API_HANDLER_HOME.getEmpDrodown(args).then((res: any) => {
      const { status, response } = res;
      if (status === 200) {
        setProjects(projectList);
        setTasks(tasksList);
        setResponsibilities(resList);
        setSpares(sparesList);
        // setProjectTypes(projectTypes);
        setEmployees(response.records);
        setLoading(false);
      }
    });
  };

  const onAddButtonClick = React.useCallback((e: any) => {
    const key = new Guid().toString();
    setChanges([
      {
        key,
        type: "insert",
        insertAfterKey: e.row.key,
      },
    ]);
    setEditRowKey(key);
  }, []);

  const isAddButtonVisible = React.useCallback(({ row }) => !row.isEditing, []);

  const getResourcePlanning = () => {
    const planDt = (document.getElementById("planDt") as HTMLInputElement)
      .value;
    setRows(null);
    const args = {
      planDt,
    };
    API_HANDLER_HOME.getResourcePlanning(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        setRows(response.records);
      }
    });
  };

  const saveRecords = () => {
    if (rows.length === 0) {
      COMMON_JS.showNotify(props, NOTIFY.WARNING, "No Data Found..!");
      return;
    }
    const planDt = (document.getElementById("planDt") as HTMLInputElement)
      .value;
    const args = { rows, planDt };
    setRows(null);
    API_HANDLER_HOME.saveResourcePlanning(args).then((res) => {
      if (res.status === 200) {
        COMMON_JS.showNotify(
          props,
          NOTIFY.SUCCESS,
          "Records Saved Successfully..!"
        );
        getResourcePlanning();
        setHasChanges(false);
      } else {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, "Error: " + res.error);
      }
    });
  };

  const onRowInserted = React.useCallback((e: any) => {
    // console.log("onRowInserted: ", e);
    e.component.navigateToRow(e.key);
  }, []);

  const onSaving = React.useCallback(
    (e: any) => {
      console.log("onSaving: ", e, changes, rows);
      e.cancel = true;
      if (!changes[0] || !changes[0].data) {
        COMMON_JS.showNotify(
          props,
          NOTIFY.WARNING,
          "Please fill the mandatory fields to continue..!"
        );
        return;
      } else {
        let rowId = changes[0].key;
        let saveType = changes[0].type;
        let chng = changes;
        if (saveType === "update") {
          let changesRow = rows.filter((r: any) => {
            return r.ID === rowId;
          });
          if (changesRow.length > 0) {
            for (let key in changesRow[0]) {
              if (key !== "ID" && key !== "resource") {
                // console.log("onSaving: chng:", key);
                if (!(key in chng[0]["data"])) {
                  chng[0]["data"][key] = changesRow[0][key];
                }
              }
            }
          }
        }
        const { projectName, responsible, spare1, spare2, task } = chng[0].data;
        if (!task) {
          COMMON_JS.showNotify(props, NOTIFY.WARNING, "Please Select Task..!");
          return;
        }
        if (!projectName) {
          COMMON_JS.showNotify(
            props,
            NOTIFY.WARNING,
            "Please Select Project..!"
          );
          return;
        }
        // if (!projectType) {
        //   COMMON_JS.showNotify(
        //     props,
        //     NOTIFY.WARNING,
        //     "Please Select Project Type..!"
        //   );
        //   return;
        // }
        if (!responsible) {
          COMMON_JS.showNotify(
            props,
            NOTIFY.WARNING,
            "Please Select Responsibility..!"
          );
          return;
        }
        // if (!spare1) {
        //   COMMON_JS.showNotify(
        //     props,
        //     NOTIFY.WARNING,
        //     "Please Select Spare1..!"
        //   );
        //   return;
        // }
        // if (!spare2) {
        //   COMMON_JS.showNotify(
        //     props,
        //     NOTIFY.WARNING,
        //     "Please Select Spare2..!"
        //   );
        //   return;
        // }
        let tmpRows = rows;
        let data = changes[0].data;
        if (rowId.indexOf("ID-") === -1) {
          // New Record
          let parentRowId = changes[0].insertAfterKey;
          if (!parentRowId) {
            parentRowId = changes[0].key;
          }
          // console.log("New Record: ", rowId, parentRowId, rows);
          // ReGenerate Rows
          let tRows = [];
          for (let indx in rows) {
            tRows.push(rows[indx]);
            if (tRows[indx]["ID"] === parentRowId) {
              if (saveType === "update") {
                for (let key in data) {
                  tRows[indx][key] = data[key];
                }
              } else {
                let empArr = employees.filter((e) => {
                  return e.name === tRows[indx]["resource"];
                });
                let row = {
                  ID: rowId,
                  resource: empArr.length > 0 ? empArr[0].name : "",
                };
                for (let key in data) {
                  row[key] = data[key];
                }
                tRows.push(row);
              }
            }
          }
          setRows(tRows);
        } else {
          // Existing Record Update
          for (let indx in rows) {
            if (tmpRows[indx]["ID"] === rowId) {
              for (let key in data) {
                tmpRows[indx][key] = data[key];
              }
              break;
            }
          }
          setRows(tmpRows);
        }
        // console.log("final: ", rows, tmpRows, rowId);
        setChanges([]);
        setEditRowKey(null);
        setHasChanges(true);
        // e.promise = saveChange(dispatch, e.changes[0]);
      }
    },
    [changes, rows]
  );

  // const onSaving = (e: any) => {
  //   // console.log("onSaving: ", changes, e);
  //   if (!changes[0] || !changes[0].data) {
  //     COMMON_JS.showNotify(
  //       props,
  //       NOTIFY.WARNING,
  //       "Please Allot the slots to save the record..!"
  //     );
  //   }
  //   e.cancel = true;
  //   // setChanges([]);
  //   // setEditRowKey(null);
  //   // e.promise = saveChange(dispatch, e.changes[0]);
  // };

  const projectRef: any = useRef(null);
  const gridRef: any = useRef(null);

  // console.log("rows: pos: ", rows, editRowKey, changes);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li className="active">{LABELS.RESOURCE_PLANNING}</li>
        </ul>
        <h4>{LABELS.RESOURCE_PLANNING}</h4>
      </div>

      <div
        id="resourcePlanning"
        style={{
          marginTop: 15,
          overflow: "auto",
          width: "98%",
        }}
      >
        {/* {!loading ? (
          <div
            style={{
              height: 300,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader />
          </div>
        ) : ( */}
        <React.Fragment>
          <div style={{ width: "30%" }}>
            <div className="formGroup">
              <label>{LABELS.DATE}</label>
              <TxtBox
                type={"date"}
                id="planDt"
                defaultValue={DateFunctions.getDate(
                  "today",
                  "from",
                  "YYYY-MM-DD"
                )}
              />
            </div>
            {/* <div className="formGroup">
              <label>{LABELS.PROJECT_NAME}</label>
              <AutoComplete
                id={"projects"}
                list={projects}
                childRef={projectRef}
              />
            </div> */}
            <div className="formGroup">
              <Btn
                text={LABELS.LOAD}
                onClick={getResourcePlanning}
                loading={loading}
              />
            </div>
          </div>
          {rows && (
            <div
              style={{
                flexDirection: "column",
              }}
            >
              <DataGrid
                id="gridContainer"
                className="resourcePlanning"
                style={{
                  overflow: "auto",
                }}
                ref={gridRef}
                dataSource={rows}
                keyExpr="ID"
                showBorders={true}
                allowColumnResizing={true}
                columnMinWidth={150}
                columnAutoWidth={true}
                onRowInserted={onRowInserted}
                onSaving={onSaving}
              >
                <Scrolling columnRenderingMode="virtual" />
                <Paging enabled={false} />
                <Editing
                  mode="row"
                  allowAdding={true}
                  allowDeleting={true}
                  allowUpdating={true}
                  confirmDelete={true}
                  useIcons={true}
                  newRowPosition={newRowPosition}
                  changes={changes}
                  onChangesChange={setChanges}
                  editRowKey={editRowKey}
                  onEditRowKeyChange={setEditRowKey}
                />
                <Column
                  dataField="resource"
                  caption="Resource"
                  groupIndex={0}
                />
                <Column dataField="task" caption="Task" width={50}>
                  <Lookup
                    dataSource={tasks}
                    displayExpr="name"
                    valueExpr="id"
                  />
                </Column>
                <Column
                  dataField="projectName"
                  caption="Project"
                  minWidth={180}
                  width={180}
                  setCellValue={(newData: any, value: any, g: any) => {
                    newData.projectName = value;
                    return API_HANDLER_HOME.getResponsibility({
                      empId: g.empId,
                      projectId: value,
                    }).then((res: any) => {
                      if (res.status === 200) {
                        newData.responsible = res.response.desiId;
                      }
                    });
                  }}
                >
                  <Lookup
                    dataSource={projects}
                    displayExpr="label"
                    valueExpr="id"
                  />
                </Column>
                {/* <Column
                  dataField="projectType"
                  caption="Project Type"
                  minWidth={150}
                  width={150}
                >
                  <Lookup
                    dataSource={projectTypes}
                    displayExpr="name"
                    valueExpr="id"
                  />
                </Column> */}
                <Column
                  dataField="responsible"
                  caption="Responsible"
                  minWidth={150}
                  width={150}
                >
                  <Lookup
                    dataSource={responsibilities}
                    displayExpr="name"
                    valueExpr="id"
                  />
                </Column>
                {/* <Column
                  dataField="spare1"
                  caption="Spare 1"
                  minWidth={70}
                  width={70}
                >
                  <Lookup
                    dataSource={spares}
                    displayExpr="name"
                    valueExpr="id"
                  />
                </Column>
                <Column
                  dataField="spare2"
                  caption="Spare 2"
                  minWidth={70}
                  width={70}
                >
                  <Lookup
                    dataSource={spares}
                    displayExpr="name"
                    valueExpr="id"
                  />
                </Column> */}
                <Column
                  dataField="slot1"
                  caption={RESOURCE_SLOTS.SLOT_1}
                  dataType="boolean"
                  minWidth={60}
                  width={60}
                />
                <Column
                  dataField="slot2"
                  caption={RESOURCE_SLOTS.SLOT_2}
                  dataType="boolean"
                  minWidth={60}
                  width={60}
                />
                <Column
                  dataField="slot3"
                  caption={RESOURCE_SLOTS.SLOT_3}
                  dataType="boolean"
                  minWidth={60}
                  width={60}
                />
                <Column
                  dataField="slot4"
                  caption={RESOURCE_SLOTS.SLOT_4}
                  dataType="boolean"
                  minWidth={60}
                  width={60}
                />
                <Column
                  dataField="slot5"
                  caption={RESOURCE_SLOTS.SLOT_5}
                  dataType="boolean"
                  minWidth={60}
                  width={60}
                />
                <Column
                  dataField="slot6"
                  caption={RESOURCE_SLOTS.SLOT_6}
                  dataType="boolean"
                  minWidth={60}
                  width={60}
                />
                <Column
                  dataField="slot7"
                  caption={RESOURCE_SLOTS.SLOT_7}
                  dataType="boolean"
                  minWidth={60}
                  width={60}
                />
                <Column
                  dataField="slot8"
                  caption={RESOURCE_SLOTS.SLOT_8}
                  dataType="boolean"
                  minWidth={60}
                  width={60}
                />
                <Column
                  dataField="slot9"
                  caption={RESOURCE_SLOTS.SLOT_9}
                  dataType="boolean"
                  minWidth={60}
                  width={60}
                />
                <Column
                  dataField="slot10"
                  caption={RESOURCE_SLOTS.SLOT_10}
                  dataType="boolean"
                  minWidth={60}
                  width={60}
                />
                <Column
                  dataField="slot11"
                  caption={RESOURCE_SLOTS.SLOT_11}
                  dataType="boolean"
                  minWidth={60}
                  width={60}
                />
                <Column
                  dataField="slot12"
                  caption={RESOURCE_SLOTS.SLOT_12}
                  dataType="boolean"
                  minWidth={60}
                  width={60}
                />
                <Column
                  dataField="slot13"
                  caption={RESOURCE_SLOTS.SLOT_13}
                  dataType="boolean"
                  minWidth={60}
                  width={60}
                />
                <Column
                  dataField="total"
                  caption="Total"
                  minWidth={100}
                  width={100}
                  alignment={"center"}
                />
                <Column
                  type="buttons"
                  caption="Options"
                  minWidth={80}
                  width={80}
                  alignment={"center"}
                >
                  <Button
                    icon="add"
                    onClick={onAddButtonClick}
                    visible={isAddButtonVisible}
                  />
                  <Button name="edit" />
                  {/* <Button name="delete" /> */}
                  <Button name="save" />
                  <Button name="cancel" />
                </Column>
                <GroupPanel visible={true} />
                <SearchPanel visible={true} />
                <Grouping autoExpandAll={true} />
              </DataGrid>

              <div
                className="formGroup"
                style={{
                  marginTop: 15,
                }}
              >
                {hasChanges && (
                  <Btn
                    text={LABELS.SAVE}
                    onClick={saveRecords}
                    loading={loading}
                  />
                )}
              </div>
            </div>
          )}
        </React.Fragment>
        {/* )} */}
      </div>
    </React.Fragment>
  );
};

export default withSnackbar(ResourcePlanning);
