import React, { useEffect, useRef, useState } from "react";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { withSnackbar } from "notistack";
import { IconButton, Tooltip } from "@mui/material";
import {
  Replay as IconReload,
  AddBox as IconAdd,
  Edit as IconEdit,
  Check as IconCheck,
  Close as IconClose,
  PictureAsPdf as IconPdf,
  Article as IconFile,
} from "@mui/icons-material";

import TxtBox from "@components/Custom/TxtBox";
import UiDialog from "@components/Custom/UiDialog";
import RadioBtn from "@components/Custom/RadioBtn";
import Btn from "@components/Custom/Btn";
import SwitchBtn from "@components/Custom/SwitchBtn";
import AutoComplete from "@components/Custom/AutoComplete";
import ConfirmDialog from "@components/Custom/ConfirmDialog";
import Loader from "@components/Custom/Loader";
import UsersSkeleton from "./UsersSkeleton";

import {
  COLORS,
  ERRORS,
  LABELS,
  NOTIFY,
  SUCCESS,
  WARNINGS,
} from "@common/config";
import { ApiHome } from "@apihandlers/home";
import { ApiPrint } from "@apihandlers/print";

import { COMMON_JS } from "@common/scripts";

const API_HANDLER_HOME = new ApiHome();
const API_HANDLER_PRINT = new ApiPrint();

let _timer = null;

const InvoicePrint = (props: any) => {
  const [invoiceList, setInvoiceList] = useState([]);

  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState<any>(null);

  const [fileId, setFileId] = useState("");
  const [users, setUsers] = useState([]);
  const [orgs, setOrgs] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [manageDialog, setManageDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});

  useEffect(() => {
    if (loading) {
      getInvoices();
    }
  }, [loading]);

  const getInvoices = () => {
    const args = {};
    API_HANDLER_HOME.getInvoices(args).then((res: any) => {
      const response: any = res.response;
      const { status } = res;
      if (status === 200) {
        let list = response.records.map((l) => {
          return {
            id: l.id,
            label: l.invoiceNumber,
          };
        });
        setInvoiceList(list);
        setLoading(false);
      }
    });
  };

  const onEditRow = (row: any) => {
    setSelectedRow(row);
    setManageDialog(true);
  };

  const columns: GridColDef[] = [
    {
      field: "slNo",
      headerName: "Sl No.",
      width: 100,
      type: "number",
      align: "center",
      headerAlign: "center",
    },
    { field: "designationName", headerName: "Designation Name", width: 300 },
    { field: "activeStatusText", headerName: "Active", width: 200 },
    {
      field: "options",
      headerName: "Options",
      width: 150,
      renderCell: (params) => (
        <div className="options">
          <Tooltip title={LABELS.EDIT} placement="bottom">
            <IconButton
              className="iconBtn"
              onClick={() => onEditRow(params.row)}
            >
              <IconEdit />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const manageRecord = () => {
    const designationName = (
      document.getElementById("designationName") as HTMLInputElement
    ).value.trim();
    const activeStatus = (
      document.getElementById("isActive") as HTMLInputElement
    ).checked;

    // Validations
    if (!designationName) {
      COMMON_JS.showNotify(
        props,
        NOTIFY.WARNING,
        WARNINGS.DESIGNATION_NAME_EMPTY
      );
      return;
    }

    const args = {
      id: selectedRow.id || -1,
      designationName,
      activeStatus,
    };
  };

  const deleteUser = ({ id }) => {
    // console.log("deleteUser: ", id);
    setRows(null);
    // getUsers();
  };

  const reloadGrid = () => {
    setRows(null);
    setTimeout(() => {
      // getRows();
    }, 2000);
  };

  const onSearch = (e: any) => {
    const qry = e.target.value.trim().toUpperCase();
    clearTimeout(_timer);
    _timer = setTimeout(() => {
      let flist = rows.filter((r: any) => {
        return r.designationName.toUpperCase().indexOf(qry) > -1;
      });
      // console.log("flist: ", rows, flist, qry);
      if (flist.length === 0) {
        flist = null;
      } else {
        flist = flist.map((f, i) => {
          f.slNo = i + 1;
          return f;
        });
      }
      setFilteredRows(flist);
    }, 150);
  };

  const printInvoice = () => {
    const invoiceId = invoiceRef.current.selectedValue.id;
    const args = {
      invoiceId,
    };
    API_HANDLER_PRINT.printInvoice(args)
      .then((res: any) => {
        if (res) {
          let base64 = "data:application/pdf;base64, " + res;
          fetch(base64).then((r) => {
            r.blob().then((data) => {
              let objUrl = window.URL.createObjectURL(data);
              // (
              //   document.getElementById("printView") as HTMLElement
              // ).setAttribute("src", objUrl);
              // (
              //   document.getElementById("printView") as HTMLElement
              // ).style.display = "flex";
              window.open(objUrl, "_blank");
              window.URL.revokeObjectURL(objUrl);
            });
          });
        } else {
          COMMON_JS.showNotify(
            props,
            NOTIFY.ERROR,
            "Invoice Copy Not Found..!"
          );
        }
      })
      .catch((err) => {
        COMMON_JS.showNotify(props, NOTIFY.ERROR, "Invoice Copy Not Found..!");
      });
  };

  const invoiceRef: any = useRef();

  // console.log("Users: ", loading, selectedRow.active);

  return (
    <React.Fragment>
      <div className="pageInfo">
        <ul className="breadcrumbs">
          <li>{LABELS.HOME}</li>
          <li className="dim">{" / "}</li>
          <li className="active">{LABELS.INVOICE_PRINT}</li>
        </ul>
        <h4>{LABELS.INVOICE_PRINT}</h4>
      </div>

      <div className="formGroup" style={{ width: "20%" }}>
        <label>{LABELS.INVOICES}</label>
        <AutoComplete
          id={"invoiceList"}
          list={invoiceList}
          childRef={invoiceRef}
        />
      </div>
      <div className="formGroup">
        <Btn text={LABELS.PRINT} onClick={printInvoice} />
      </div>
    </React.Fragment>
  );
};

export default withSnackbar(InvoicePrint);
